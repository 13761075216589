import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21be1299"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"grid","grid-template-columns":"3fr 1fr","align-items":"center","width":"100%"} }
const _hoisted_2 = { style: {"margin-left":"10px","font-weight":"bold"} }
const _hoisted_3 = { class: "pool-status" }
const _hoisted_4 = {
  key: 0,
  style: {"margin":"auto"}
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { style: {"padding-right":"15px","width":"100%"} }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 0,
  class: "commision"
}
const _hoisted_10 = {
  key: 1,
  class: "commision"
}
const _hoisted_11 = {
  key: 2,
  class: "commision"
}
const _hoisted_12 = {
  key: 3,
  class: "commision"
}
const _hoisted_13 = { key: 1 }
const _hoisted_14 = {
  key: 0,
  style: {"cursor":"pointer"}
}
const _hoisted_15 = { class: "extended-datatable" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("span", null, [
    ($setup.currentBoost)
      ? (_openBlock(), _createBlock($setup["LoyaltyDropPopup"], {
          key: 0,
          visible: $setup.popupOpened,
          boost: $setup.currentBoost,
          onClose: _cache[0] || (_cache[0] = ($event: any) => {$setup.popupOpened = false;})
        }, null, 8 /* PROPS */, ["visible", "boost"]))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["DataTableWrapper"], {
      "data-key": 'pool_description',
      useExternalGlobalFilter: false,
      "eager-loading-config": $setup.createEagerLoadingConfig(),
      "expanded-rows": $setup.expandedRow,
      paginator: false
    }, {
      empty: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('BOOST.TABLE.NO_DATA')), 1 /* TEXT */)
      ]),
      columns: _withCtx(() => [
        _createVNode(_component_Column, {
          header: _ctx.$t('BOOST.COMMON.NAME'),
          sortable: false
        }, {
          body: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.calculateRockets(slotProps.data.poolDescription ), (i) => {
                    return (_openBlock(), _createBlock($setup["Icon"], {
                      key: i,
                      name: "Rocket",
                      style: _normalizeStyle(['color:'+$setup.calculateColor(i), {"height":"18px"}])
                    }, null, 8 /* PROPS */, ["style"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _createTextVNode(" " + _toDisplayString(slotProps.data.poolDescription), 1 /* TEXT */)
              ]),
              _createElementVNode("div", _hoisted_3, [
                (slotProps.data.epochStartDate.getTime() - new Date().getTime() > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode($setup["BoosterCounter"], {
                        "start-date": slotProps.data.epochStartDate
                      }, null, 8 /* PROPS */, ["start-date"])
                    ]))
                  : (slotProps.data.baseTokens.amount > new $setup.Coin(slotProps.data.reservedTokens.amount, slotProps.data.reservedTokens.denom).add(slotProps.data.usedTokens).amount)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode($setup["StatusBadge"], { status: true })
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode($setup["StatusBadge"], { status: false })
                      ]))
              ])
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["header"]),
        _createVNode(_component_Column, {
          header: _ctx.$t('BOOST.COMMON.LOCKUP_PERIOD'),
          sortable: false
        }, {
          body: _withCtx((slotProps) => [
            _createElementVNode("span", null, _toDisplayString($setup.msToDays(slotProps.data.lockupPeriod)) + " " + _toDisplayString(_ctx.$t('BOOST.COMMON.DAYS')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["header"]),
        _createVNode(_component_Column, {
          header: _ctx.$t('BOOST.COMMON.APR'),
          sortable: false
        }, {
          body: _withCtx((slotProps) => [
            _createElementVNode("span", null, _toDisplayString(($setup.calculateApr(slotProps.data)).toFixed(2)) + "%", 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["header"]),
        _createVNode(_component_Column, {
          header: _ctx.$t('BOOST.TABLE.POOL_SIZE'),
          sortable: false
        }, {
          body: _withCtx((slotProps) => [
            _createVNode($setup["CoinAmount"], {
              amount: slotProps.data.baseTokens,
              "show-denom": true,
              "show-tooltip": true
            }, null, 8 /* PROPS */, ["amount"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["header"]),
        _createVNode(_component_Column, {
          header: _ctx.$t('BOOST.COMMON.POOL_USAGE')
        }, {
          body: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_7, [
              _createVNode($setup["CoinAmount"], {
                amount: $setup.calculatePoolUsageTokens(slotProps.data) ,
                "show-tooltip": true,
                "tooltip-only": ""
              }, {
                default: _withCtx(() => [
                  ($setup.calculatePercentagePoolUsage(slotProps.data))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        ($setup.calculatePercentagePoolUsage(slotProps.data).isBiggerThanOrEqualTo(0.90))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                              _createElementVNode("div", {
                                class: "level-1",
                                style: _normalizeStyle('flex-basis:' + ($setup.calculatePercentagePoolUsage(slotProps.data)).multiply(100).toFixed(2) + '%')
                              }, null, 4 /* STYLE */),
                              _createVNode($setup["PercentsView"], {
                                class: "level-border",
                                amount: $setup.calculatePercentagePoolUsage(slotProps.data),
                                precision: 2
                              }, null, 8 /* PROPS */, ["amount"])
                            ]))
                          : _createCommentVNode("v-if", true),
                        ($setup.calculatePercentagePoolUsage(slotProps.data).isBiggerThanOrEqualTo(0.80) && !$setup.calculatePercentagePoolUsage(slotProps.data).isBiggerThanOrEqualTo(0.90))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                              _createElementVNode("div", {
                                class: "level-2",
                                style: _normalizeStyle('flex-basis:' + ($setup.calculatePercentagePoolUsage(slotProps.data)).multiply(100).toFixed(2) + '%')
                              }, null, 4 /* STYLE */),
                              _createVNode($setup["PercentsView"], {
                                class: "level-border",
                                amount: $setup.calculatePercentagePoolUsage(slotProps.data),
                                precision: 2
                              }, null, 8 /* PROPS */, ["amount"])
                            ]))
                          : _createCommentVNode("v-if", true),
                        ($setup.calculatePercentagePoolUsage(slotProps.data).isBiggerThanOrEqualTo(0.70) && !$setup.calculatePercentagePoolUsage(slotProps.data).isBiggerThanOrEqualTo(0.80))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                              _createElementVNode("div", {
                                class: "level-3",
                                style: _normalizeStyle('flex-basis:' + ($setup.calculatePercentagePoolUsage(slotProps.data)).multiply(100).toFixed(2) + '%')
                              }, null, 4 /* STYLE */),
                              _createVNode($setup["PercentsView"], {
                                class: "level-border",
                                amount: $setup.calculatePercentagePoolUsage(slotProps.data),
                                precision: 2
                              }, null, 8 /* PROPS */, ["amount"])
                            ]))
                          : _createCommentVNode("v-if", true),
                        (!$setup.calculatePercentagePoolUsage(slotProps.data).isBiggerThanOrEqualTo(0.70))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              _createElementVNode("div", {
                                class: "level-4",
                                style: _normalizeStyle('flex-basis:' + ($setup.calculatePercentagePoolUsage(slotProps.data)).multiply(100).toFixed(2) + '%')
                              }, null, 4 /* STYLE */),
                              _createVNode($setup["PercentsView"], {
                                class: "level-border",
                                amount: $setup.calculatePercentagePoolUsage(slotProps.data),
                                precision: 2
                              }, null, 8 /* PROPS */, ["amount"])
                            ]))
                          : _createCommentVNode("v-if", true)
                      ]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_13, "updating"))
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["amount"])
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["header"]),
        _createVNode(_component_Column, {
          header: _ctx.$t('BOOST.TABLE.CONTRIBUTION'),
          sortable: false
        }, {
          body: _withCtx((slotProps) => [
            _createVNode($setup["CoinAmount"], {
              amount: $setup.calculateContributionPerPool($setup.boostStore.getUserBoosts,slotProps.data.id),
              "show-denom": true,
              "show-tooltip": true
            }, null, 8 /* PROPS */, ["amount"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["header"]),
        _createVNode(_component_Column, {
          header: _ctx.$t('BOOST.TABLE.REWARD'),
          sortable: false
        }, {
          body: _withCtx((slotProps) => [
            _createVNode($setup["CoinAmount"], {
              amount: $setup.calculateRewardsPerPool($setup.boostStore.getUserBoosts,slotProps.data.id),
              "show-denom": true,
              "show-tooltip": true
            }, null, 8 /* PROPS */, ["amount"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["header"]),
        _createVNode(_component_Column, null, {
          body: _withCtx((slotProps) => [
            _createVNode(_component_Button, {
              class: "outlined-secondary",
              onClick: ($event: any) => ($setup.checkBTN(slotProps.data)),
              disabled: $setup.checkIfActive(slotProps.data)
            }, {
              default: _withCtx(() => [
                _createVNode($setup["StakeManagementIcon"], { icon: "manage" }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('BOOST.TABLE.MANAGE')), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick", "disabled"])
          ]),
          _: 1 /* STABLE */
        }),
        ($setup.isLoggedIn)
          ? (_openBlock(), _createBlock(_component_Column, { key: 0 }, {
              body: _withCtx((slotProps) => [
                ($setup.boostStore.getUserBoosts.find(el => el.boostPoolId === slotProps.data.id))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                      _createCommentVNode("  <Icon @click=\"onRowExpand(data)\" name=\"ChevronRight\" /> "),
                      _createVNode($setup["Icon"], {
                        onClick: ($event: any) => ($setup.onRowExpand(slotProps.data)),
                        name: $setup.expandedRow.length && $setup.expandedRow[0].poolDescription === slotProps.data.poolDescription ? 'ChevronUp' : 'ChevronRight'
                      }, null, 8 /* PROPS */, ["onClick", "name"])
                    ]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ]),
      "expanded-columns": _withCtx((slotProps1) => [
        _createElementVNode("div", _hoisted_15, [
          _createVNode($setup["DataTableWrapper"], {
            useExternalGlobalFilter: false,
            "eager-loading-config": $setup.createUserDropLoadingConfig(slotProps1.expandedData.data.id),
            paginator: false
          }, {
            columns: _withCtx(() => [
              _createVNode(_component_Column, {
                header: _ctx.$t('BOOST.TABLE.LOCK_START'),
                style: {"width":"200px","text-align":"left"},
                sortable: false
              }, {
                body: _withCtx((slotProps) => [
                  _createElementVNode("span", null, _toDisplayString($setup.checkDateIsDefined(slotProps.data.lockStart)), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('BOOST.TABLE.LOCK_END'),
                style: {"width":"200px","text-align":"left"},
                sortable: false
              }, {
                body: _withCtx((slotProps) => [
                  _createElementVNode("span", null, _toDisplayString($setup.checkDateIsDefined(slotProps.data.lockEnd)), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('BOOST.TABLE.CONTRIBUTION'),
                style: {"width":"200px","text-align":"left"},
                sortable: false
              }, {
                body: _withCtx((slotProps) => [
                  _createVNode($setup["CoinAmount"], {
                    amount:  slotProps.data.amount,
                    "show-denom": true,
                    "show-tooltip": true
                  }, null, 8 /* PROPS */, ["amount"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('BOOST.TABLE.LAST_REWARD_DATE'),
                style: {"width":"200px","text-align":"left"},
                sortable: false
              }, {
                body: _withCtx((slotProps) => [
                  _createElementVNode("span", null, _toDisplayString($setup.checkDateIsDefined(slotProps.data.lastRewardDate)), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('BOOST.TABLE.LAST_REWARD'),
                style: {"width":"200px","text-align":"left"},
                sortable: false
              }, {
                body: _withCtx((slotProps) => [
                  _createVNode($setup["CoinAmount"], {
                    amount: slotProps.data.lastRewardDate?$setup.calculatePayouts(slotProps1.expandedData.data, slotProps.data.amount):'0',
                    "show-denom": true,
                    "show-tooltip": false
                  }, null, 8 /* PROPS */, ["amount"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('BOOST.TABLE.STATUS'),
                style: {"width":"200px","text-align":"right"},
                sortable: false
              }, {
                body: _withCtx((slotProps) => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('BOOST.TABLE.STATUS_TYPE.' + slotProps.data.status )), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["header"])
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["eager-loading-config"])
        ]),
        _createCommentVNode("        <div v-for=\"userBoost in userBoosts.filter(el => el.boostPoolId === slotProps.expandedData.data.id)\" :key=\"userBoost.boostPoolId\"  class=\"boostDetails__header\">"),
        _createCommentVNode("          <div class=\"boostDetails__header__tile\" >"),
        _createCommentVNode("            <h3>{{$t('BOOST.TABLE.LOCK_START')}}:</h3>"),
        _createCommentVNode("            <h4>{{ userBoost.lockStart?.toLocaleDateString() }}</h4>"),
        _createCommentVNode("          </div>"),
        _createCommentVNode("          <div class=\"boostDetails__header__tile\" >"),
        _createCommentVNode("            <h3>{{$t('BOOST.TABLE.LOCK_END')}}:</h3>"),
        _createCommentVNode("            <h4>{{ userBoost.lockEnd?.toLocaleDateString() }}</h4>"),
        _createCommentVNode("          </div>"),
        _createCommentVNode("                    <div class=\"boostDetails__header__tile\" >"),
        _createCommentVNode("             <h3>Your contribution:</h3>"),
        _createCommentVNode("                      &lt;!&ndash;            <h4>{{ userBoost.amount    }}</h4>&ndash;&gt;"),
        _createCommentVNode("            <h4> <CoinAmount :amount=\"userBoost.amount\" :show-denom=\"true\"/></h4>"),
        _createCommentVNode("          </div>"),
        _createCommentVNode("          <div class=\"boostDetails__header__tile\" >"),
        _createCommentVNode("            <h3>{{$t('BOOST.TABLE.LAST_REWARD')}}:</h3>"),
        _createCommentVNode("            <h4>{{ userBoost.lastRewardDate?userBoost.lastRewardDate.toLocaleDateString():\"-\" }}</h4>"),
        _createCommentVNode("          </div>"),
        _createCommentVNode("        </div>")
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["eager-loading-config", "expanded-rows"])
  ]))
}