import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cabbb9e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "container-fluid"
}
const _hoisted_2 = { class: "inflation" }
const _hoisted_3 = { class: "stacking-apr" }
const _hoisted_4 = { class: "pools" }
const _hoisted_5 = { class: "tokenomics" }
const _hoisted_6 = {
  key: 0,
  class: "circulating-supply"
}
const _hoisted_7 = { class: "total-supply" }
const _hoisted_8 = { class: "validators" }
const _hoisted_9 = { class: "latest-block" }
const _hoisted_10 = { class: "max-supply" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.configConfigured !== '')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode($setup["InfoBanner"], { style: {"margin-bottom":"20px"} }),
        _createElementVNode("div", {
          class: _normalizeClass(["dashboard-grid", $setup.circulatingSupplyVisible?'dashboard-grid':'dashboard-grid-noCS'])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode($setup["DashboardInflation"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode($setup["StakingApr"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode($setup["DashboardPools"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode($setup["TokenomicsDashboard"])
          ]),
          ($setup.circulatingSupplyVisible)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode($setup["CirculatingSupply"])
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_7, [
            _createVNode($setup["TotalSupply"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode($setup["ActiveValidators"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode($setup["LatestBlock"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode($setup["AverageBlockTime"])
          ])
        ], 2 /* CLASS */)
      ]))
    : _createCommentVNode("v-if", true)
}