export class Block {
  height: number;
  time: Date;

  constructor (height: number, time: Date) {
    this.height = height;
    this.time = time;
  }

}
