import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "sidebar display-none" }
const _hoisted_3 = { class: "sidebar-element" }
const _hoisted_4 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return ($setup.menu.length > 0)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createElementVNode("div", {
          onClick: $setup.showSidebar,
          class: "open-sidebar"
        }, [
          _createVNode($setup["Icon"], { name: "SidebarOpen" })
        ]),
        _createElementVNode("nav", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.menu, (menuItem, index) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              to: menuItem.href,
              key: index
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_3, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["icon", { 'active': index === $setup.selected }])
                  }, [
                    (menuItem.icon.type === $setup.SideBarIconType.LUCIDE)
                      ? (_openBlock(), _createBlock($setup["Icon"], {
                          key: 0,
                          name: menuItem.icon.element
                        }, null, 8 /* PROPS */, ["name"]))
                      : (menuItem.icon.type === $setup.SideBarIconType.GOV)
                        ? (_openBlock(), _createBlock($setup["GovernanceIcon"], {
                            key: 1,
                            icon: menuItem.icon.element
                          }, null, 8 /* PROPS */, ["icon"]))
                        : (menuItem.icon.type == $setup.SideBarIconType.FAUCET)
                          ? (_openBlock(), _createBlock($setup["FaucetIcon"], { key: 2 }))
                          : _createCommentVNode("v-if", true)
                  ], 2 /* CLASS */),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('SECTION_TITLES.'+ $setup.toUpperCase(menuItem.title))), 1 /* TEXT */)
                ])
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))
          }), 128 /* KEYED_FRAGMENT */)),
          _createElementVNode("span", {
            onClick: $setup.hideSidebar,
            class: "hide-sidebar"
          }, [
            _createVNode($setup["Icon"], { name: "SidebarClose" })
          ])
        ])
      ]))
    : _createCommentVNode("v-if", true)
}