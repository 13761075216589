<template>
  <div v-if="props.icon === 'c4e-circle'" class="c4e-icon" >
    <svg :width="props.size" :height="props.size" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.268 27.8207C11.4745 27.8139 8.74571 26.9973 6.42638 25.4741C4.10705 23.9509 2.30123 21.7894 1.23702 19.2627C0.172818 16.7359 -0.102009 13.9573 0.447245 11.2779C0.996499 8.59846 2.34519 6.13838 4.32296 4.20841C6.30073 2.27844 8.81884 0.965187 11.5592 0.434548C14.2995 -0.0960907 17.1392 0.179693 19.7194 1.22705C22.2996 2.27441 24.5046 4.04637 26.0559 6.31907C27.6071 8.59177 28.4351 11.2632 28.4351 13.996C28.4258 17.6657 26.929 21.182 24.2732 23.7736C21.6174 26.3653 18.0192 27.8207 14.268 27.8207ZM14.268 1.22863C11.6881 1.23469 9.16786 1.98862 7.02574 3.3952C4.88362 4.80178 3.21571 6.79789 2.23271 9.13136C1.2497 11.4648 0.99571 14.031 1.50282 16.5056C2.00993 18.9802 3.25539 21.2522 5.08186 23.0347C6.90833 24.8172 9.23387 26.0302 11.7647 26.5203C14.2955 27.0105 16.918 26.7559 19.301 25.7886C21.6839 24.8214 23.7204 23.185 25.1531 21.086C26.5858 18.9871 27.3504 16.5199 27.3504 13.996C27.3421 10.6071 25.9602 7.35972 23.5076 4.96624C21.0551 2.57277 17.7323 1.22862 14.268 1.22863Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.84768 4.82898C6.84768 5.29619 6.71115 5.75293 6.45536 6.14141C6.19956 6.52988 5.83601 6.83266 5.41064 7.01146C4.98527 7.19025 4.51721 7.23704 4.06564 7.1459C3.61408 7.05474 3.19928 6.82975 2.87372 6.49938C2.54815 6.169 2.32645 5.74809 2.23663 5.28985C2.1468 4.8316 2.1929 4.35662 2.36909 3.92497C2.54529 3.49332 2.84365 3.12437 3.22647 2.8648C3.60929 2.60523 4.05937 2.46667 4.51979 2.46667C5.13719 2.46667 5.7293 2.71556 6.16586 3.15857C6.60243 3.60159 6.84768 4.20245 6.84768 4.82898Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6658 27.1787C14.6658 27.6459 14.5293 28.1026 14.2735 28.4911C14.0177 28.8796 13.6541 29.1824 13.2288 29.3612C12.8034 29.54 12.3353 29.5868 11.8838 29.4956C11.4322 29.4045 11.0174 29.1795 10.6918 28.8491C10.3663 28.5187 10.1446 28.0978 10.0547 27.6396C9.96492 27.1813 10.011 26.7063 10.1872 26.2747C10.3634 25.843 10.6618 25.4741 11.0446 25.2145C11.4274 24.9549 11.8775 24.8164 12.3379 24.8164C12.9553 24.8164 13.5474 25.0653 13.984 25.5083C14.4205 25.9513 14.6658 26.5522 14.6658 27.1787Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M28.971 9.56161C28.971 10.0288 28.8345 10.4855 28.5787 10.874C28.3229 11.2625 27.9593 11.5653 27.5339 11.7441C27.1086 11.9229 26.6405 11.9697 26.1889 11.8785C25.7374 11.7874 25.3226 11.5624 24.997 11.232C24.6714 10.9016 24.4497 10.4807 24.3599 10.0225C24.2701 9.56423 24.3162 9.08925 24.4924 8.65759C24.6686 8.22594 24.967 7.857 25.3498 7.59742C25.7326 7.33785 26.1827 7.19931 26.6431 7.19931C27.2605 7.19931 27.8526 7.44819 28.2892 7.89121C28.7257 8.33423 28.971 8.93509 28.971 9.56161Z" fill="currentColor"/>
      <path d="M17.9726 22.255C18.9363 21.7668 19.801 21.099 20.5216 20.2865V8.6482C19.802 7.83464 18.9371 7.16668 17.9726 6.67961V13.8886H10.2401V6.73867C9.2812 7.2409 8.42326 7.92057 7.71045 8.74268V15.1918C7.71074 15.3632 7.74367 15.533 7.80744 15.6918C7.87076 15.8452 7.96153 15.9854 8.07515 16.1052C8.18719 16.2189 8.31866 16.3109 8.46314 16.3769C8.62318 16.4486 8.79645 16.4848 8.97139 16.4832H17.9571L17.9726 22.255Z" fill="currentColor"/>
    </svg>
  </div>
  <div v-else-if="props.icon === 'c4e-circle-no-dots'" class="c4e-icon" >
    <svg :width="props.size" :height="props.size" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9626 30C11.9975 29.9926 9.10106 29.1066 6.63925 27.4539C4.17744 25.8012 2.26068 23.4559 1.1311 20.7144C0.00151953 17.9728 -0.290191 14.9579 0.292805 12.0507C0.875801 9.1435 2.30734 6.47427 4.40661 4.38021C6.50588 2.28616 9.17868 0.861256 12.0874 0.285502C14.996 -0.290251 18.0102 0.0089796 20.7489 1.14538C23.4876 2.28179 25.828 4.2044 27.4746 6.67032C29.1211 9.13624 30 12.0348 30 14.9999C29.9901 18.9816 28.4014 22.7969 25.5824 25.6088C22.7635 28.4209 18.9442 30 14.9626 30ZM14.9626 1.1471C12.2242 1.15367 9.54914 1.9717 7.27543 3.49787C5.00171 5.02403 3.23134 7.18985 2.18795 9.72171C1.14456 12.2535 0.874963 15.0379 1.41323 17.7229C1.95149 20.4079 3.27346 22.8731 5.21213 24.8071C7.1508 26.7411 9.61921 28.0573 12.3055 28.589C14.9918 29.1209 17.7754 28.8447 20.3048 27.7951C22.8341 26.7457 24.9957 24.9702 26.5164 22.6927C28.0371 20.4154 28.8487 17.7384 28.8487 14.9999C28.8399 11.3229 27.3731 7.79945 24.7698 5.20247C22.1666 2.60551 18.6397 1.14709 14.9626 1.1471Z" fill="currentColor"/>
      <path d="M19.2145 24C20.2676 23.4671 21.2125 22.7383 22 21.8515V9.14865C21.2136 8.26068 20.2685 7.53162 19.2145 7V14.8684H10.7644V7.06446C9.71651 7.61263 8.77896 8.35446 8 9.25177V16.2908C8.00032 16.4778 8.0363 16.6632 8.10599 16.8365C8.17519 17.0039 8.27438 17.1569 8.39854 17.2877C8.52098 17.4118 8.66465 17.5122 8.82254 17.5843C8.99743 17.6625 9.18678 17.702 9.37795 17.7003H19.1975L19.2145 24Z" fill="currentColor"/>
    </svg>
  </div>
  <div v-else-if="props.icon === 'c4e-4-only'" class="c4e-icon" >
    <svg :width="props.size" :height="props.size" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.2145 24C20.2676 23.4671 21.2125 22.7383 22 21.8515V9.14865C21.2136 8.26068 20.2685 7.53162 19.2145 7V14.8684H10.7644V7.06446C9.71651 7.61263 8.77896 8.35446 8 9.25177V16.2908C8.00032 16.4778 8.0363 16.6632 8.10599 16.8365C8.17519 17.0039 8.27438 17.1569 8.39854 17.2877C8.52098 17.4118 8.66465 17.5122 8.82254 17.5843C8.99743 17.6625 9.18678 17.702 9.37795 17.7003H19.1975L19.2145 24Z" fill="currentColor"/>
    </svg>
  </div>
  <div v-else-if="props.icon === 'c4e-green'" class="c4e-icon" >
    <svg :width="props.size*2" :height="props.size" viewBox="0 0 86 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M41.8686 38.5286C38.1747 38.5191 34.5663 37.3812 31.4993 35.2586C28.4324 33.1361 26.0445 30.1241 24.6373 26.6032C23.23 23.0823 22.8666 19.2104 23.5929 15.4767C24.3192 11.7429 26.1026 8.31486 28.7179 5.6255C31.3332 2.93615 34.663 1.10616 38.2867 0.366727C41.9103 -0.372704 45.6653 0.0115932 49.0772 1.47106C52.4891 2.93053 55.4049 5.3997 57.4562 8.56665C59.5075 11.7336 60.6023 15.4562 60.6023 19.2643C60.59 24.3779 58.6108 29.2778 55.0989 32.8891C51.587 36.5005 46.8291 38.5286 41.8686 38.5286ZM41.8686 1.47326C38.4571 1.4817 35.1245 2.53229 32.2919 4.49232C29.4593 6.45235 27.2538 9.23388 25.9539 12.4855C24.654 15.7371 24.3182 19.313 24.9887 22.7613C25.6593 26.2096 27.3062 29.3756 29.7214 31.8595C32.1366 34.3433 35.2118 36.0335 38.5584 36.7166C41.905 37.3996 45.3729 37.0448 48.5239 35.697C51.675 34.3492 54.3679 32.0688 56.2624 29.144C58.1569 26.2192 59.168 22.7812 59.168 19.2643C59.1571 14.5419 57.3296 10.0168 54.0865 6.68152C50.8435 3.34628 46.4496 1.47325 41.8686 1.47326Z" fill="url(#paint0_linear_3623_35287)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M32.1393 6.31465C32.1393 6.94808 31.9571 7.5673 31.6157 8.09398C31.2743 8.62067 30.7892 9.03116 30.2215 9.27356C29.6538 9.51597 29.0291 9.57941 28.4265 9.45583C27.8238 9.33225 27.2702 9.02721 26.8358 8.57931C26.4013 8.1314 26.1054 7.56074 25.9855 6.93948C25.8656 6.31821 25.9272 5.67425 26.1623 5.08903C26.3974 4.50382 26.7956 4.00362 27.3065 3.6517C27.8174 3.29978 28.4181 3.11194 29.0326 3.11194C29.8565 3.11194 30.6467 3.44936 31.2294 4.04999C31.812 4.65061 32.1393 5.46524 32.1393 6.31465Z" fill="#2AFD88"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M42.5728 37.7866C42.5728 38.4201 42.3906 39.0393 42.0492 39.566C41.7079 40.0926 41.2227 40.5031 40.655 40.7455C40.0873 40.988 39.4626 41.0514 38.86 40.9278C38.2573 40.8042 37.7037 40.4992 37.2693 40.0513C36.8348 39.6034 36.5389 39.0327 36.419 38.4114C36.2991 37.7902 36.3607 37.1462 36.5958 36.561C36.8309 35.9758 37.2292 35.4756 37.7401 35.1237C38.251 34.7718 38.8516 34.5839 39.4661 34.5839C40.29 34.5839 41.0802 34.9213 41.6629 35.522C42.2455 36.1226 42.5728 36.9372 42.5728 37.7866Z" fill="#2AFD88"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M61.6638 12.7308C61.6638 13.3642 61.4816 13.9834 61.1402 14.5101C60.7989 15.0368 60.3137 15.4473 59.746 15.6897C59.1783 15.9321 58.5536 15.9955 57.951 15.8719C57.3483 15.7484 56.7947 15.4433 56.3603 14.9954C55.9258 14.5475 55.6299 13.9769 55.51 13.3556C55.3901 12.7343 55.4517 12.0904 55.6868 11.5052C55.9219 10.9199 56.3202 10.4197 56.8311 10.0678C57.342 9.7159 57.9426 9.52808 58.5571 9.52808C59.381 9.52808 60.1712 9.8655 60.7539 10.4661C61.3365 11.0667 61.6638 11.8814 61.6638 12.7308Z" fill="#2AFD88"/>
      <path d="M46.9844 29.94C48.2706 29.278 49.4246 28.3726 50.3863 27.2711V11.4924C49.4259 10.3894 48.2716 9.48381 46.9844 8.82346V18.5971H36.6648V8.90352C35.3851 9.58443 34.2401 10.5059 33.2888 11.6205V20.3639C33.2892 20.5963 33.3332 20.8265 33.4183 21.0418C33.5028 21.2497 33.6239 21.4398 33.7755 21.6023C33.9251 21.7564 34.1005 21.8812 34.2933 21.9706C34.5069 22.0678 34.7382 22.1169 34.9716 22.1147H46.9637L46.9844 29.94Z" fill="#2AFD88"/>
      <defs>
        <linearGradient id="paint0_linear_3623_35287" x1="32.5225" y1="2.57285" x2="52.0807" y2="35.4405" gradientUnits="userSpaceOnUse">
          <stop stop-color="#0AFFA7"/>
          <stop offset="1" stop-color="#82FFB9"/>
        </linearGradient>
      </defs>
    </svg>
  </div>
  <div v-else-if="props.icon === 'c4e-grey-new'" class="c4e-icon" >
    <svg
      id="Warstwa_2"
      data-name="Warstwa 2"
      viewBox="0 0 255.99999 255.99999"
      version="1.1"
      :width="props.size*2"
      :height="props.size"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg">
      <g
        id="Warstwa_1-2"
        transform="matrix(1.2794476,0,0,1.2794476,2,2)">
        <g
          id="g128">
          <circle
            cx="98.480003"
            cy="98.480003"
            r="98.480003"
            id="circle120"
            fill="#233149"/>
          <g
            id="g126">
            <path
              d="m 176.94,73.99 c 0,-6.76 -5.48,-12.24 -12.23,-12.24 -0.68,0 -1.34,0.07 -1.99,0.18 -12.79,-21.81 -36.5,-36.5 -63.61,-36.5 -16.21,0 -31.21,5.26 -43.39,14.15 -2,-1.43 -4.45,-2.29 -7.1,-2.29 -6.76,0 -12.24,5.48 -12.24,12.23 0,2.73 0.9,5.24 2.42,7.27 -8.4,11.93 -13.35,26.45 -13.35,42.11 0,32.99 21.91,60.97 51.98,70.22 0,0.15 -0.02,0.29 -0.02,0.44 0,6.76 5.47,12.23 12.23,12.23 5.81,0 10.66,-4.05 11.91,-9.48 39.49,-1.29 71.21,-33.71 71.21,-73.41 0,-5.01 -0.51,-9.89 -1.47,-14.62 3.39,-2.17 5.64,-5.96 5.64,-10.3 z m -9.81,24.92 c 0,36.6 -29.22,66.51 -65.61,67.78 -1.3,-5.36 -6.11,-9.34 -11.88,-9.34 -4.62,0 -8.65,2.57 -10.72,6.35 -27.68,-8.6 -47.83,-34.39 -47.83,-64.79 0,-14.28 4.45,-27.53 12.03,-38.47 1.66,0.83 3.52,1.32 5.5,1.32 6.75,0 12.23,-5.47 12.23,-12.23 0,-2.05 -0.51,-3.99 -1.41,-5.69 11.18,-8.03 24.87,-12.78 39.67,-12.78 24.77,0 46.48,13.28 58.38,33.07 -3.04,2.23 -5.02,5.81 -5.02,9.86 0,6.77 5.48,12.23 12.24,12.23 0.4,0 0.8,-0.02 1.2,-0.06 0.79,4.13 1.22,8.39 1.22,12.75 z"
              id="path122"
              fill="#fff"/>
            <path
              d="M 119.23,59.12 V 96.4 H 78.65 V 59.39 c -5.05,2.6 -9.54,6.13 -13.27,10.36 v 33.35 c 0,0.92 0.18,1.78 0.51,2.6 0.34,0.82 0.81,1.52 1.4,2.13 0.59,0.59 1.31,1.07 2.12,1.42 0.81,0.36 1.7,0.54 2.66,0.54 h 47.16 v 29.82 c 5.08,-2.52 9.6,-5.96 13.37,-10.11 V 69.26 c -3.77,-4.17 -8.29,-7.61 -13.37,-10.14 z"
              id="path124"
              fill="#fff"/>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script setup lang="ts">
export type C4EIconType = 'c4e-circle' | 'c4e-circle-no-dots' | 'c4e-4-only' | 'c4e-green';
const props = defineProps<{
 icon: C4EIconType,
 size: string | number,
}>();

</script>
<style scoped>

</style>
