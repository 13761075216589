export enum PagesEnum{
  DASHBOARD,
  STAKING,
  GOVERNANCE,
  FAUCET,
  AIRDROP,
  BUYTOKENS,
  PROFILE,
  PORTFOLIO,
  BOOST,
  DISTRIBUTION
}
