<script setup lang="ts">

</script>

<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="34" height="34" viewBox="0 0 512.000000 512.000000"
       preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
       fill="#72bf44" stroke="none">
      <path d="M2585 3815 c-14 -13 -25 -31 -25 -39 0 -13 -31 -15 -199 -18 -227 -3
-243 -9 -270 -85 -13 -36 -12 -43 5 -79 32 -67 60 -74 277 -74 l187 0 0 -79 0
-78 -40 -6 c-54 -7 -89 -31 -107 -75 -19 -45 -11 -88 24 -124 15 -15 25 -29
22 -30 -2 -2 -31 -16 -64 -33 -33 -16 -88 -53 -122 -82 l-62 -53 -305 0 -305
0 -3 149 c-3 133 -5 153 -24 177 -39 53 -71 69 -134 69 -63 0 -95 -16 -134
-69 -21 -27 -21 -36 -21 -686 0 -650 0 -659 21 -686 39 -53 71 -69 134 -69 63
0 95 16 134 69 19 25 21 44 24 217 l3 189 274 0 273 0 25 -43 c14 -24 56 -71
92 -105 113 -106 236 -155 390 -154 161 0 285 52 401 167 75 75 136 179 149
254 l7 41 118 0 c66 0 130 -5 143 -11 36 -16 47 -50 47 -143 0 -83 0 -84 -29
-95 -40 -17 -51 -49 -51 -151 0 -99 10 -132 47 -149 33 -15 513 -15 546 0 37
17 47 50 47 149 0 102 -11 134 -51 151 l-29 11 0 284 c0 300 -3 323 -47 343
-16 7 -150 11 -418 11 l-393 0 -21 33 c-47 76 -201 195 -274 213 l-28 7 25 26
c34 35 42 78 23 123 -18 44 -53 68 -107 75 l-40 6 0 78 0 79 188 0 c216 0 244
7 276 74 17 36 18 43 5 79 -27 76 -43 82 -270 85 -168 3 -199 5 -199 18 0 26
-47 64 -80 64 -19 0 -40 -9 -55 -25z"/>
      <path d="M3665 1499 c-99 -157 -198 -345 -231 -440 -21 -61 -25 -85 -20 -133
12 -120 85 -223 195 -275 80 -37 192 -37 272 0 110 52 183 155 195 275 7 71
-13 138 -88 287 -43 86 -192 339 -231 391 -13 17 -20 9 -92 -105z"/>
    </g>
  </svg>
</template>

<style scoped lang="scss">

</style>
