<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path class="color" d="M5 13a10 10 0 0 1 14 0"></path><path class="color" d="M8.5 16.5a5 5 0 0 1 7 0"></path><path class="color" d="M2 8.82a15 15 0 0 1 20 0"></path><line x1="12" y1="20" x2="12.01" y2="20"></line></svg>
</template>

<script>

</script>

<style scoped>
.color {
  color: #72bf44;
}
</style>
