import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a11c1fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["TabView"], { class: "airDrop__tabview" }, {
      default: _withCtx(() => [
        _createVNode($setup["TabPanel"], null, {
          header: _withCtx(() => [
            _createElementVNode("div", null, _toDisplayString(_ctx.$t('DISTRIBUTION.CLAIM_AIRDROP')), 1 /* TEXT */)
          ]),
          default: _withCtx(() => [
            _createVNode($setup["ClaimAirDrop"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}