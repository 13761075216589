import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-304a2234"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal fade",
  tabindex: "-1",
  "aria-labelledby": "exampleModalLabel",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = {
  class: "modal-title",
  id: "exampleModalLabel"
}
const _hoisted_6 = { class: "vote-options" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(_toDisplayString() + " ", 1 /* TEXT */),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.YOUR_VOTE")), 1 /* TEXT */)
          ]),
          _createElementVNode("div", null, " #" + _toDisplayString($props.proposalId) + " " + _toDisplayString($props.title), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              class: _normalizeClass(["option yes", $setup.picked == $setup.VoteOption.Yes ? 'picked' : '']),
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.changeVotingOption($setup.VoteOption.Yes)))
            }, [
              _createVNode(_component_Icon, { name: "CheckSquare" }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.VOTING_OPTIONS.YES")), 1 /* TEXT */)
            ], 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass(["option no", $setup.picked == $setup.VoteOption.No ? 'picked' : '']),
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.changeVotingOption($setup.VoteOption.No)))
            }, [
              _createVNode(_component_Icon, { name: "XCircle" }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.VOTING_OPTIONS.NO")), 1 /* TEXT */)
            ], 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass(["option no-with-veto", $setup.picked == $setup.VoteOption.NoWithVeto ? 'picked' : '']),
              onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.changeVotingOption($setup.VoteOption.NoWithVeto)))
            }, [
              _createVNode(_component_Icon, { name: "UserX" }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.VOTING_OPTIONS.NO_WITH_VETO")), 1 /* TEXT */)
            ], 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass(["option abstain", $setup.picked == $setup.VoteOption.Abstain ? 'picked' : '']),
              onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.changeVotingOption($setup.VoteOption.Abstain)))
            }, [
              _createVNode(_component_Icon, { name: "Grab" }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.VOTING_OPTIONS.ABSTAIN")), 1 /* TEXT */)
            ], 2 /* CLASS */)
          ]),
          ($setup.useUserStore().isLoggedIn)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                ($setup.useUserStore().getTotalDelegated === 0n)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t('GOVERNANCE_VIEW.ERRORS.NO_STAKED')), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true),
                _createVNode(_component_Button, {
                  disabled: $setup.useUserStore().getTotalDelegated === 0n,
                  onClick: $setup.onVoteClick,
                  label: _ctx.$t('GOVERNANCE_VIEW.VOTE'),
                  class: "p-button-raised p-button-rounded",
                  "data-bs-dismiss": "modal"
                }, null, 8 /* PROPS */, ["disabled", "label"])
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_9, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.VOTE_CONDITION")), 1 /* TEXT */),
                _createVNode(_component_Button, {
                  onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.dataService.onKeplrLogIn())),
                  class: "p-button-raised p-button-rounded"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["KeplrLogo"], { letter: "K" }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('CONNECT.CONNECT' )), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]))
        ])
      ])
    ])
  ]))
}