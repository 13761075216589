import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c3592a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tiers_container" }
const _hoisted_2 = { style: {"display":"flex","align-items":"center","justify-content":"center","flex-direction":"column"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tierInfoList, (tier) => {
        return (_openBlock(), _createBlock($setup["KycTier"], {
          class: "tiers_container__single",
          key: tier,
          header: tier.header,
          description: tier.description,
          "list-of-steps": tier.steps,
          tier: tier.tier,
          onVerify: $setup.onVerifyButton
        }, null, 8 /* PROPS */, ["header", "description", "list-of-steps", "tier"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createVNode($setup["Dialog"], {
      visible: $setup.modalVisible,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (($setup.modalVisible) = $event)),
      modal: "",
      header: "Verification",
      style: { width: '95vw', 'max-width': '600px' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode($setup["SynapsVerify"], {
            sessionId: $setup.sessionId,
            color: { primary: '72bf44', secondary: 'ffffff' },
            lang: "en",
            tier: $setup.selectedTierId,
            service: "individual",
            onReady: _cache[0] || (_cache[0] = ($event: any) => (console.log('ready'))),
            onFinish: _cache[1] || (_cache[1] = ($event: any) => (console.log('finish')))
          }, null, 8 /* PROPS */, ["sessionId", "tier"])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["visible"])
  ]))
}