export enum ServiceTypeEnum {
  ROUTER = 'ROUTER',
  VALIDATORS_API = 'VALIDATORS_API',
  PROPOSAL_API = 'PROPOSAL_API',
  ACCOUNT_API = 'ACCOUNT_API',
  TOKENS_API = 'TOKENS_API',
  BLOCK_API = 'BLOCK_API',
  KEYBASE_API = 'KEYBASE_API',
  WALLET_API = 'WALLET_API',
  AIR_DROP_API= 'AIR_DROP_API',
  USER_SERVICE_API= 'USER_SERVICE_API',
  SALE_SERVICE_API='SALE_SERVICE_API',
  USER_STORE = 'USER_STORE',
  VALIDATORS_STORE = 'VALIDATORS_STORE',
  TOKENS_STORE = 'TOKENS_STORE',
  BLOCK_STORE = 'BLOCK_STORE',
  DATA_SERVICE = 'DATA_SERVICE',
  CONFIG_STORE = 'CONFIG_STORE',
  PROPOSAL_STORE = 'PROPOSAL_STORE',
  AIR_DROP_STORE = 'AIR_DROP_STORE',
  FAUCET_API = 'FAUCET_API',
  PUBLIC_SALE_SERVICE_API= 'PUBLIC_SALE_SERVICE_API',
  LOYALTY_DROP_API = 'LOYALTY_DROP',
}
