import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29c3aa70"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin-top":"40px","padding-bottom":"60px"} }
const _hoisted_2 = { class: "login_container box-shadow" }
const _hoisted_3 = { class: "login_container__header" }
const _hoisted_4 = { style: {"font-weight":"900","padding-top":"20px"} }
const _hoisted_5 = { class: "login_container__body" }
const _hoisted_6 = { style: {"padding":"10px 30px"} }
const _hoisted_7 = { class: "field col-12" }
const _hoisted_8 = { class: "invalid-feedback" }
const _hoisted_9 = { class: "flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("SIGN_IN_VIEW.EMAIL")), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode($setup["Form"], {
          onSubmit: $setup.next,
          "validation-schema": $setup.schema
        }, {
          default: _withCtx(({errors}) => [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode($setup["Field"], {
                    style: {"width":"100%"},
                    modelValue: $setup.email,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.email) = $event)),
                    placeholder: _ctx.$t('SIGN_IN_VIEW.EMAIL'),
                    name: "email",
                    type: "text",
                    class: _normalizeClass(["form-control", {'is-invalid': errors.email}])
                  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "class"]),
                  _createElementVNode("div", _hoisted_8, _toDisplayString(errors.email ? _ctx.$t(errors.email) : ''), 1 /* TEXT */)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_Button, {
                class: "p-button p-component secondary",
                style: {"width":"40%"},
                onClick: _cache[1] || (_cache[1] = ($event: any) => {$setup.emit('prevPage', {pageIndex: 0});})
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('COMMON.BACK')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_Button, {
                class: "p-button p-component secondary",
                disabled: !$setup.email,
                style: {"width":"40%"},
                type: "submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('COMMON.NEXT')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["disabled"])
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["validation-schema"])
      ])
    ])
  ]))
}