import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f0f3234"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"padding-bottom":"60px","margin-top":"100px"} }
const _hoisted_2 = {
  style: {"min-height":"400px"},
  class: "login_container box-shadow"
}
const _hoisted_3 = { class: "login_container__header" }
const _hoisted_4 = { style: {"font-weight":"900"} }
const _hoisted_5 = { class: "login_container__body" }
const _hoisted_6 = { style: {"padding":"10px 30px"} }
const _hoisted_7 = { class: "field col-12" }
const _hoisted_8 = { class: "invalid-feedback" }
const _hoisted_9 = { class: "field col-12" }
const _hoisted_10 = {
  style: {"margin-top":"0.25rem","font-size":"0.875em","color":"#dc3545"},
  class: "invalid"
}
const _hoisted_11 = { class: "field col-12" }
const _hoisted_12 = {
  style: {"margin-top":"0.25rem","font-size":"0.875em","color":"#dc3545"},
  class: "invalid"
}
const _hoisted_13 = { class: "mx-2" }
const _hoisted_14 = { style: {"margin-top":"0.25rem","font-size":"0.875em","color":"#dc3545"} }
const _hoisted_15 = { class: "flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("SIGN_IN_VIEW.SIGN_UP")), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode($setup["Form"], {
          onSubmit: $setup.register,
          "validation-schema": $setup.schema
        }, {
          default: _withCtx(({errors}) => [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode($setup["Field"], {
                    style: {"width":"100%"},
                    modelValue: $setup.email,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.email) = $event)),
                    placeholder: _ctx.$t('SIGN_IN_VIEW.EMAIL'),
                    name: "email",
                    type: "text",
                    class: _normalizeClass(["form-control", {'is-invalid': errors.email}])
                  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "class"]),
                  _createElementVNode("div", _hoisted_8, _toDisplayString(errors.email ? _ctx.$t(errors.email) : ''), 1 /* TEXT */)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode($setup["Field"], {
                    style: {"width":"100%"},
                    modelValue: $setup.password,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.password) = $event)),
                    placeholder: _ctx.$t('SIGN_IN_VIEW.PASSWORD'),
                    name: "password",
                    type: "text",
                    class: _normalizeClass(["form-control", {'is-invalid': errors.password}])
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["Password"], {
                        style: {"width":"100%"},
                        modelValue: $setup.password,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.password) = $event)),
                        name: "password",
                        placeholder: _ctx.$t('SIGN_IN_VIEW.PASSWORD'),
                        toggleMask: "",
                        feedback: false
                      }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "placeholder", "class"]),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(errors.password ? _ctx.$t(errors.password) : ''), 1 /* TEXT */)
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode($setup["Field"], {
                    style: {"width":"100%"},
                    modelValue: $setup.passwordRetype,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.passwordRetype) = $event)),
                    placeholder: _ctx.$t('SIGN_IN_VIEW.RETYPE'),
                    name: "passwordRetype",
                    type: "text",
                    class: _normalizeClass(["form-control", {'is-invalid': errors.passwordRetype}])
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["Password"], {
                        style: {"width":"100%"},
                        modelValue: $setup.passwordRetype,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.passwordRetype) = $event)),
                        name: "passwordRetype",
                        placeholder: _ctx.$t('SIGN_IN_VIEW.RETYPE'),
                        toggleMask: "",
                        feedback: false
                      }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "placeholder", "class"]),
                  _createElementVNode("div", _hoisted_12, _toDisplayString(errors.passwordRetype ? _ctx.$t(errors.passwordRetype) : ''), 1 /* TEXT */)
                ])
              ]),
              _createElementVNode("div", null, [
                _createVNode($setup["Field"], {
                  name: "termsAccepted",
                  modelValue: $setup.termsAccepted,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.termsAccepted) = $event)),
                  type: "checkbox",
                  class: _normalizeClass({'is-invalid': errors.termsAccepted})
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["Checkbox"], {
                      name: "termsAccepted",
                      modelValue: $setup.termsAccepted,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.termsAccepted) = $event)),
                      binary: true
                    }, null, 8 /* PROPS */, ["modelValue"]),
                    _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('SIGN_IN_VIEW.TERMS')), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_14, _toDisplayString(errors.termsAccepted ? _ctx.$t(errors.termsAccepted) : ''), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "class"])
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_Button, {
                class: "p-button p-component secondary",
                style: {"width":"40%"},
                type: "submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('SIGN_IN_VIEW.SIGN_UP')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _createVNode(_component_RouterLink, {
              class: "register",
              to: $setup.useRoute().path.includes('buyTokens') ? '/buyTokens/signIn' : '/profile/signIn'
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('SIGN_IN_VIEW.SIGN_IN')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["to"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["validation-schema"])
      ])
    ])
  ]))
}