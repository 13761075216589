import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c99f100"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("span", null, [
    ($props.showDenom && !$props.tooltipOnly && $props.denomAsPrefix)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, " " + _toDisplayString($setup.getDenom()), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    (!$props.tooltipOnly)
      ? (_openBlock(), _createBlock($setup["FormattedNumber"], {
          key: 1,
          amount: $setup.retrieveConvertedAmount(),
          precision: $props.precision,
          reduceBigNumber: $props.reduceBigNumber,
          onClick: $setup.copyValue
        }, null, 8 /* PROPS */, ["amount", "precision", "reduceBigNumber"]))
      : _createCommentVNode("v-if", true),
    ($props.showDenom && !$props.tooltipOnly && !$props.denomAsPrefix)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, " " + _toDisplayString($setup.getDenom()), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ])), [
    [_directive_tooltip, { value: (( $props.denomAsPrefix ? $setup.getDenom() : '') + $setup.retrieveConvertedAmount().toFixed($props.tooltipPrecision) + ' ' + (!$props.denomAsPrefix ? $setup.getDenom() : '')), disabled:!$setup.props.showTooltip, class:'coin-amount-tooltip'}]
  ])
}