import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-108679b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { ref: "otpCont" }
const _hoisted_2 = ["onUpdate:modelValue", "autofocus", "placeholder", "onPaste", "onKeydown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.digits, (el, ind) => {
      return _withDirectives((_openBlock(), _createElementBlock("input", {
        type: "text",
        class: _normalizeClass(["digit-box", {bounce: $setup.digits[ind] !== null}]),
        key: el+ind,
        "onUpdate:modelValue": ($event: any) => (($setup.digits[ind]) = $event),
        autofocus: ind === 0,
        placeholder: ind+1,
        maxlength: "1",
        onPaste: ($event: any) => ($setup.handlePaste($event, ind)),
        onKeydown: ($event: any) => ($setup.handleKeyDown($event, ind))
      }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_2)), [
        [_vModelText, $setup.digits[ind]]
      ])
    }), 128 /* KEYED_FRAGMENT */))
  ], 512 /* NEED_PATCH */))
}