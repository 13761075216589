import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c66ac53c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loginEmail" }
const _hoisted_2 = { class: "loginEmail__header" }
const _hoisted_3 = { class: "loginEmail__description" }
const _hoisted_4 = { class: "field" }
const _hoisted_5 = { class: "invalid-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t('CONNECT.CONNECT_ADDRESS')), 1 /* TEXT */),
      _createVNode(_component_Button, {
        icon: "pi pi-times",
        style: {"width":"5px"},
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back'))),
        class: "p-button-rounded p-button-secondary p-button-text"
      })
    ]),
    _createVNode($setup["Form"], {
      onSubmit: $setup.submit,
      "validation-schema": $setup.amountSchema,
      class: "loginEmail__body"
    }, {
      default: _withCtx(({ errors }) => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode($setup["Field"], {
              modelValue: $setup.address,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.address) = $event)),
              name: "address",
              placeholder: " ",
              type: "text",
              class: _normalizeClass(["form-control", { 'is-invalid': errors.address }]),
              style: {"width":"100%"}
            }, null, 8 /* PROPS */, ["modelValue", "class"]),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('CONNECT.ADDRESS_HELP')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_5, _toDisplayString(errors.address ? errors.address : ""), 1 /* TEXT */),
            _createVNode(_component_Button, { type: "submit" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('COMMON.CONNECT')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["validation-schema"])
  ]))
}