import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c7e3b70"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin-top":"100px","padding-bottom":"60px"} }
const _hoisted_2 = { class: "login_container box-shadow" }
const _hoisted_3 = { class: "login_container__header" }
const _hoisted_4 = { style: {"font-weight":"900","padding-top":"20px"} }
const _hoisted_5 = { class: "login_container__body" }
const _hoisted_6 = { style: {"padding":"10px 30px 0"} }
const _hoisted_7 = { class: "field col-12" }
const _hoisted_8 = { class: "invalid-feedback" }
const _hoisted_9 = { class: "field col-12" }
const _hoisted_10 = { class: "invalid" }
const _hoisted_11 = { class: "flex justify-content-center" }
const _hoisted_12 = { style: {"padding":"5px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("SIGN_IN_VIEW.SIGN_IN")), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode($setup["Form"], {
          onSubmit: $setup.login,
          "validation-schema": $setup.schema
        }, {
          default: _withCtx(({errors}) => [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode($setup["Field"], {
                    style: {"width":"100%"},
                    modelValue: $setup.email,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.email) = $event)),
                    placeholder: _ctx.$t('SIGN_IN_VIEW.EMAIL'),
                    name: "email",
                    type: "text",
                    class: _normalizeClass(["form-control", {'is-invalid': errors.email}])
                  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "class"]),
                  _createElementVNode("div", _hoisted_8, _toDisplayString(errors.email ? _ctx.$t(errors.email) : ''), 1 /* TEXT */)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode($setup["Field"], {
                    style: {"width":"100%"},
                    modelValue: $setup.password,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.password) = $event)),
                    placeholder: _ctx.$t('SIGN_IN_VIEW.PASSWORD'),
                    name: "password",
                    type: "text",
                    class: _normalizeClass(["form-control", {'is-invalid': errors.password}])
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["Password"], {
                        style: {"width":"100%"},
                        modelValue: $setup.password,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.password) = $event)),
                        name: "password",
                        placeholder: _ctx.$t('SIGN_IN_VIEW.PASSWORD'),
                        toggleMask: "",
                        feedback: false
                      }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "placeholder", "class"]),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(errors.password ? _ctx.$t(errors.password) : ''), 1 /* TEXT */)
                ])
              ])
            ]),
            _createElementVNode("p", {
              class: "forgot",
              onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.router.push('/profile/reset')))
            }, "Forgot password?"),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_Button, {
                class: "p-button p-component secondary",
                style: {"width":"40%"},
                type: "submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('SIGN_IN_VIEW.SIGN_IN')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_RouterLink, {
                class: "register",
                to: $setup.useRoute().path.includes('buyTokens') ? '/buyTokens/signUp' : '/profile/signUp'
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('SIGN_IN_VIEW.REGISTER')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"])
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["validation-schema"])
      ])
    ])
  ]))
}