import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-907c6760"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t('BOOST.TITLE')), 1 /* TEXT */),
    _createVNode($setup["LoyaltyDropDataTable"]),
    _createVNode(_component_Button, {
      class: "outlined mt-5",
      onClick: $setup.withdraw
    }, {
      default: _withCtx(() => [
        _createVNode($setup["StakeManagementIcon"], { icon: "manage" }),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('BOOST.WITHDRAW_ALL')), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    })
  ], 64 /* STABLE_FRAGMENT */))
}