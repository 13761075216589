import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49ca837a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "dropdown-btn"
}
const _hoisted_2 = {
  key: 0,
  class: "options-container"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "switch",
    onFocusout: $setup.closeDropdown,
    tabindex: "0",
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.toggleDropdown()))
  }, [
    _createCommentVNode("    <font-awesome-icon icon=\"globe\" class=\"icon\"/>"),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.locales, (locale) => {
      return (_openBlock(), _createElementBlock("span", {
        key: locale.file
      }, [
        (locale.file == _ctx.$i18n.locale)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
              _createVNode($setup["CountryFlag"], {
                country: locale.flagCode
              }, null, 8 /* PROPS */, ["country"])
            ]))
          : _createCommentVNode("v-if", true)
      ]))
    }), 128 /* KEYED_FRAGMENT */)),
    ($setup.dropdown)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.locales, (locale) => {
            return (_openBlock(), _createElementBlock("span", {
              class: "option",
              onClick: ($event: any) => ($setup.setLocale(locale.file)),
              key: locale.file
            }, [
              _createVNode($setup["CountryFlag"], {
                country: locale.flagCode
              }, null, 8 /* PROPS */, ["country"]),
              _createElementVNode("span", _hoisted_4, _toDisplayString(locale.name), 1 /* TEXT */)
            ], 8 /* PROPS */, _hoisted_3))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ], 32 /* NEED_HYDRATION */))
}