import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cb0241fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "warning" }
const _hoisted_2 = { class: "warning-container" }
const _hoisted_3 = {
  key: 0,
  class: "warning-text"
}
const _hoisted_4 = {
  key: 1,
  class: "warning-text",
  style: {"margin-bottom":"0 !important"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Icon, { name: "AlertTriangle" }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t($props.header, $props.headerVariables)), 1 /* TEXT */),
      ($setup.textTypeData.type === $setup.TextType.STRING)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t($props.texts, $props.textsVariables)), 1 /* TEXT */))
        : ($setup.textTypeData.type === $setup.TextType.ARRAY)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.textTypeData.amount, (index) => {
                return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(_ctx.$t(`${$props.texts}[${index - 1}]`, $props.textsVariables)), 1 /* TEXT */))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true)
    ])
  ]))
}