import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6839d59a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "details-container"
}
const _hoisted_2 = {
  key: 0,
  class: "voting-status voting"
}
const _hoisted_3 = {
  key: 1,
  class: "voting-status rejected"
}
const _hoisted_4 = {
  key: 2,
  class: "voting-status accepted"
}
const _hoisted_5 = {
  key: 3,
  class: "voting-status deposit"
}
const _hoisted_6 = {
  key: 4,
  class: "voting-status failed"
}
const _hoisted_7 = { class: "id" }
const _hoisted_8 = { style: {"padding-left":"20px"} }
const _hoisted_9 = {
  key: 5,
  class: "vote user-vote-yes"
}
const _hoisted_10 = {
  key: 6,
  class: "vote user-vote-abstain"
}
const _hoisted_11 = {
  key: 7,
  class: "vote user-vote-no"
}
const _hoisted_12 = {
  key: 8,
  class: "vote user-vote-veto"
}
const _hoisted_13 = { class: "info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return ($props.proposal)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($props.proposal.status == 'PROPOSAL_STATUS_VOTING_PERIOD')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_Icon, {
                name: $setup.icons.get($props.proposal.status)
              }, null, 8 /* PROPS */, ["name"]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("GOVERNANCE_VIEW."+$props.proposal.status)), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true),
        ($props.proposal.status == 'PROPOSAL_STATUS_REJECTED')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_Icon, {
                name: $setup.icons.get($props.proposal.status)
              }, null, 8 /* PROPS */, ["name"]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("GOVERNANCE_VIEW."+$props.proposal.status)), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true),
        ($props.proposal.status == 'PROPOSAL_STATUS_PASSED')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_Icon, {
                name: $setup.icons.get($props.proposal.status)
              }, null, 8 /* PROPS */, ["name"]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("GOVERNANCE_VIEW."+$props.proposal.status)), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true),
        ($props.proposal.status == 'PROPOSAL_STATUS_DEPOSIT_PERIOD')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_Icon, {
                name: $setup.icons.get($props.proposal.status)
              }, null, 8 /* PROPS */, ["name"]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("GOVERNANCE_VIEW."+$props.proposal.status)), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true),
        ($props.proposal.status == 'PROPOSAL_STATUS_FAILED')
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_Icon, {
                name: $setup.icons.get($props.proposal.status)
              }, null, 8 /* PROPS */, ["name"]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("GOVERNANCE_VIEW."+$props.proposal.status)), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("h3", null, "#" + _toDisplayString($props.proposal.proposalId), 1 /* TEXT */)
        ]),
        _createElementVNode("h4", _hoisted_8, _toDisplayString($props.proposal?.content?.title), 1 /* TEXT */),
        ($setup.voted === $setup.VoteOption.Yes)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createTextVNode(_toDisplayString(_ctx.$t("GOVERNANCE_VIEW.USER_VOTE")) + " ", 1 /* TEXT */),
              _createElementVNode("b", null, _toDisplayString(_ctx.$t('GOVERNANCE_VIEW.VOTING_OPTIONS.YES')), 1 /* TEXT */)
            ]))
          : ($setup.voted === $setup.VoteOption.Abstain)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createTextVNode(_toDisplayString(_ctx.$t("GOVERNANCE_VIEW.USER_VOTE")) + " ", 1 /* TEXT */),
                _createElementVNode("b", null, _toDisplayString(_ctx.$t('GOVERNANCE_VIEW.VOTING_OPTIONS.ABSTAIN')), 1 /* TEXT */)
              ]))
            : ($setup.voted === $setup.VoteOption.No)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createTextVNode(_toDisplayString(_ctx.$t("GOVERNANCE_VIEW.USER_VOTE")) + " ", 1 /* TEXT */),
                  _createElementVNode("b", null, _toDisplayString(_ctx.$t('GOVERNANCE_VIEW.VOTING_OPTIONS.NO')), 1 /* TEXT */)
                ]))
              : ($setup.voted === $setup.VoteOption.NoWithVeto)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createTextVNode(_toDisplayString(_ctx.$t("GOVERNANCE_VIEW.USER_VOTE")) + " ", 1 /* TEXT */),
                    _createElementVNode("b", null, _toDisplayString(_ctx.$t('GOVERNANCE_VIEW.VOTING_OPTIONS.NO_WITH_VETO')), 1 /* TEXT */)
                  ]))
                : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.TOTAL_DEPOSIT")) + ":", 1 /* TEXT */),
          _createVNode($setup["CoinAmount"], {
            amount: $props.proposal.getTotalDepositByDenom(),
            "show-denom": true
          }, null, 8 /* PROPS */, ["amount"]),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.VOTING_START")) + ":", 1 /* TEXT */),
          _createElementVNode("span", null, [
            _createVNode($setup["DateCommon"], {
              date: $props.proposal.votingStartTime,
              showTime: true
            }, null, 8 /* PROPS */, ["date"])
          ]),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.VOTING_END")) + ":", 1 /* TEXT */),
          _createElementVNode("span", null, [
            _createVNode($setup["DateCommon"], {
              date: $props.proposal.votingEndTime,
              showTime: true
            }, null, 8 /* PROPS */, ["date"])
          ]),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.TYPE")) + ":", 1 /* TEXT */),
          _createVNode($setup["ProposalType"], { proposal: $props.proposal }, null, 8 /* PROPS */, ["proposal"]),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.SUBMIT_TIME")) + ":", 1 /* TEXT */),
          _createElementVNode("span", null, [
            _createVNode($setup["DateCommon"], {
              date: $props.proposal.submitTime,
              showTime: true
            }, null, 8 /* PROPS */, ["date"])
          ]),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.DEPOSIT_END_TIME")) + ":", 1 /* TEXT */),
          _createElementVNode("span", null, [
            _createVNode($setup["DateCommon"], {
              date: $props.proposal.depositEndTime,
              showTime: true
            }, null, 8 /* PROPS */, ["date"])
          ]),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.QUORUM")) + ":", 1 /* TEXT */),
          _createElementVNode("span", null, [
            _createVNode($setup["PercentsView"], {
              amount: $setup.proposalsStore.getTallyParams.quorum,
              precision: 2
            }, null, 8 /* PROPS */, ["amount"])
          ]),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.THRESHOLD")) + ":", 1 /* TEXT */),
          _createElementVNode("span", null, [
            _createVNode($setup["PercentsView"], {
              amount: $setup.proposalsStore.getTallyParams.threshold,
              precision: 2
            }, null, 8 /* PROPS */, ["amount"])
          ]),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.VETO_THRESHOLD")) + ":", 1 /* TEXT */),
          _createElementVNode("span", null, [
            _createVNode($setup["PercentsView"], {
              amount: $setup.proposalsStore.getTallyParams.vetoThreshold,
              precision: 2
            }, null, 8 /* PROPS */, ["amount"])
          ]),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.VIEW_IN_EXPLORER")) + ":", 1 /* TEXT */),
          _createElementVNode("span", null, [
            _createElementVNode("a", {
              href: $setup.url,
              target: "_blank"
            }, _toDisplayString($setup.url))
          ])
        ])
      ]))
    : _createCommentVNode("v-if", true)
}