<template>
<!--  <AddressModal style="position: fixed; z-index: 99999" @close="hideAddAddress" @submit="submitEmail" v-if="showAddressAdd" />-->
  <ApprovalModal @close="hideApprovalModal" @submit="hideApprovalModal" v-if="showApprovalModal"/>
  <div class="userProfile">
    <div class="userProfile__holder">
      <TabView>
        <TabPanel class="userProfile__tabHeader">
          <template #header>{{$t('PROFILE_VIEW.ACCOUNT_INFO')}}</template>
          <AccountInfo :accordion="false" @open-modal="showAddressAddModal" @open-approval="showApprovalModalFunc"/>
        </TabPanel>
        <TabPanel>
          <template #header>{{$t('PROFILE_VIEW.TRANSACTIONS')}}</template>
        </TabPanel>
      </TabView>
    </div>

    <div class="userProfile__extra">
      <Button v-if="useUserServiceStore().isLoggedIn"
        class="p-button p-component secondary userProfile__btn"
        @click="logOutAccount()">{{$t('BUTTONS.LOGOUT')}}</Button>
    </div>
<!--    <PayModal v-model:display="showModal" v-model:reservation="selectedReservation" @close="showModal = false" />-->
  </div>
</template>

<script lang="ts" setup>
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import AccountInfo from "@/components/transactions/AccountInfo.vue";
import { usePublicSalesStore} from "@/store/publicSales.store";
import { onBeforeMount, onMounted, ref} from "vue";
import {useUserServiceStore} from "@/store/userService.store";
import ApprovalModal from "@/components/buyTokens/modals/ApprovalModal.vue";
import {useRouter} from "vue-router";

const showAddressAdd = ref(false);
const showApprovalModal = ref(false);
const router = useRouter();

onBeforeMount(() => {
  usePublicSalesStore().fetchTokenReservations();
});

onMounted(() =>{
  useUserServiceStore().getAccount(()=>{console.log(1);}, ()=>{console.log(2);});
  useUserServiceStore().getKycStatus();
});


function logOutAccount(){
  useUserServiceStore().logoutAccount();
  router.push('/buyTokens/signIn');
}
function showAddressAddModal(){
  showAddressAdd.value = true;
}
function showApprovalModalFunc(){
  showApprovalModal.value = true;
}
function hideApprovalModal(){
  showApprovalModal.value = false;
}
</script>

<style scoped lang="scss">
.userProfile{
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  padding: 25px 46px;
  border-radius: 5px;
  align-items: flex-end;
  @media (max-width: 1024px) {
    padding: 0;
  }
  &__holder{
    width: 100%;
    align-items: center;
  }
  &__extra{
    padding: 83px 0 0 0;
    display: flex;
    align-items: flex-end;
    right: 0;
  }
  &__btn{
    border-radius: 24px;
    width: 161px;
    min-height: 40px;
    font-family: 'Work Sans',sans-serif;
  }

}
</style>
