import { createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    _createVNode($setup["FormattedNumber"], {
      amount: $setup.amount,
      precision: $props.precision,
      "remove-dec": $props.removeDec
    }, null, 8 /* PROPS */, ["amount", "precision", "remove-dec"]),
    _createTextVNode("%")
  ]))
}