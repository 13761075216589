<template>
  <div class="about">
    <h3>This is a faq page</h3>
  </div>

</template>

<script setup lang="ts">

</script>
<style lang="scss" scoped>


</style>
<style>

</style>
