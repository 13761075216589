import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-613babfa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.checkIfDisplay())
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.DETAILS")), 1 /* TEXT */),
        ($props.proposal?.content?.type===$setup.ProposalType.PARAMETER_CHANGE)
          ? (_openBlock(), _createBlock($setup["ProposalChanges"], {
              key: 0,
              "proposal-changes": $props.proposal.content?.changes
            }, null, 8 /* PROPS */, ["proposal-changes"]))
          : _createCommentVNode("v-if", true),
        ($props.proposal?.content?.type===$setup.ProposalType.SOFTWARE_UPGRADE)
          ? (_openBlock(), _createBlock($setup["ProposalPlan"], {
              key: 1,
              "proposal-plan": $props.proposal.content.plan
            }, null, 8 /* PROPS */, ["proposal-plan"]))
          : _createCommentVNode("v-if", true),
        ($props.proposal?.content?.type===$setup.ProposalType.COMMUNITY_POOL_SPEND)
          ? (_openBlock(), _createBlock($setup["ProposalCommunityPoolSpend"], {
              key: 2,
              "proposal-content": $props.proposal.content
            }, null, 8 /* PROPS */, ["proposal-content"]))
          : _createCommentVNode("v-if", true),
        ($props.proposal?.type!==$setup.ProposalType.LEGACY_CONTENT)
          ? (_openBlock(), _createBlock($setup["ProposalNotLegacyContent"], {
              key: 3,
              proposal: $props.proposal
            }, null, 8 /* PROPS */, ["proposal"]))
          : _createCommentVNode("v-if", true)
      ]))
    : _createCommentVNode("v-if", true)
}