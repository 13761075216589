import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9546505c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "badge active"
}
const _hoisted_2 = {
  key: 1,
  class: "badge jailed"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.props.status)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('BOOST.STATUS.ACTIVE')), 1 /* TEXT */))
    : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('BOOST.STATUS.CLOSED')), 1 /* TEXT */))
}