import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("span", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.onLinkClick())),
    class: _normalizeClass(["tooltip-icon", { 'pointer': $props.tooltipLink }])
  }, [
    _withDirectives((_openBlock(), _createElementBlock("span", null, [
      _createTextVNode(_toDisplayString($props.text) + " ", 1 /* TEXT */),
      _renderSlot(_ctx.$slots, "default"),
      _createVNode(_component_Icon, {
        name: "HelpCircle",
        style: {"margin-left":"5px"}
      })
    ])), [
      [
        _directive_tooltip,
        { value: $props.tooltipText, escape: true },
        void 0,
        { left: true }
      ]
    ])
  ], 2 /* CLASS */))
}