import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c117fd4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "amount" }
const _hoisted_2 = { class: "amount__amount" }
const _hoisted_3 = {
  key: 0,
  class: "header"
}
const _hoisted_4 = {
  key: 0,
  class: "vesting-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "logo-front", {}, undefined, true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["userdata-container", $setup.useUserStore().isContinuousVestingAccount || $setup.useUserStore().isPeriodicVestingAccount ? '' : 'width-95'])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.coins, (items, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "userdata-option",
            key: index
          }, [
            (items.header)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(items.header), 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            _createVNode($setup["CoinAmount"], {
              amount: $setup.convertAmount(items.amount),
              precision: $props.precision,
              "show-denom": items.showDenom || $props.showDenom,
              "show-tooltip": true,
              "reduce-big-number": $props.reduceBigNumber
            }, null, 8 /* PROPS */, ["amount", "precision", "show-denom", "reduce-big-number"]),
            (items.showPrice)
              ? (_openBlock(), _createBlock($setup["CoinAmount"], {
                  key: 1,
                  style: {"font-size":"0.7rem","font-weight":"normal"},
                  amount: $setup.calculatePrice(items.amount, $setup.price),
                  "default-view-denom": "$",
                  "tooltip-precision": 2,
                  precision: $props.precision,
                  "reduce-big-number": $props.reduceBigNumber,
                  "show-tooltip": true,
                  "show-denom": true,
                  "denom-as-prefix": true,
                  "allow-copy-value": false
                }, null, 8 /* PROPS */, ["amount", "precision", "reduce-big-number"]))
              : _createCommentVNode("v-if", true)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ], 2 /* CLASS */),
      (($setup.useUserStore().isContinuousVestingAccount || $setup.useUserStore().isPeriodicVestingAccount) && $props.showVesting)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
            _createElementVNode("div", {
              class: "vesting-flag",
              onClick: $setup.onVestingClick
            }, "Vesting"),
            _createCommentVNode("        <div class=\"userdata-option vesting-first\" v-if=\"useUserStore().isContinuousVestingAccount || useUserStore().isPeriodicVestingAccount\">"),
            _createCommentVNode("              <span class=\"header\">{{ $t('USER_DATA.LOCKED') }}</span>"),
            _createCommentVNode("              <CoinAmount :key=\"locked\" :amount=\"convertAmount(locked)\" :precision=\"precision\" :reduce-big-number=\"reduceBigNumber\" :show-tooltip=\"true\" :show-denom=\"true\"/>"),
            _createCommentVNode("              <CoinAmount style=\"font-size: 0.7rem\""),
            _createCommentVNode("                          :amount=\"calculatePrice(locked, price)\""),
            _createCommentVNode("                          default-view-denom=\"$\""),
            _createCommentVNode("                          :tooltip-precision=2"),
            _createCommentVNode("                          :precision=\"precision\""),
            _createCommentVNode("                          :reduce-big-number=\"reduceBigNumber\" :show-tooltip=\"true\" :show-denom=\"true\"  :denom-as-prefix=\"true\"/>"),
            _createCommentVNode("        </div>"),
            _createCommentVNode("        <div class=\"userdata-option vesting\" v-if=\"useUserStore().isContinuousVestingAccount\">"),
            _createCommentVNode("              <span class=\"header\">{{ $t('USER_DATA.VESTING_END') }}</span>"),
            _createCommentVNode("              <b><DateCommon :date=\"useUserStore().getAccount.continuousVestingData?.endTime\" :show-time=\"false\" :showTooltip=\"true\" /></b>"),
            _createCommentVNode("        </div>"),
            _createCommentVNode("        <div class=\"userdata-option vesting\" v-if=\"useUserStore().isPeriodicVestingAccount\">"),
            _createCommentVNode("              <span class=\"header\">{{ $t('USER_DATA.VESTING_END') }}</span>"),
            _createCommentVNode("              <b><DateCommon :date=\"useUserStore().getMaxTime\" :show-time=\"false\" :showTooltip=\"true\" /></b>"),
            _createCommentVNode("        </div>"),
            _createCommentVNode("        <div class=\"userdata-option vesting\" v-if=\"!useUserStore().isContinuousVestingAccount || useUserStore().isPeriodicVestingAccount\"></div>")
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _renderSlot(_ctx.$slots, "logo-back", {}, undefined, true)
  ]))
}