import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode($setup["Steps"], {
        model: $setup.items,
        "aria-label": "Form Steps"
      }, null, 8 /* PROPS */, ["model"])
    ]),
    _createVNode(_component_router_view, {
      newPassword: $setup.newPassword,
      "onUpdate:newPassword": _cache[0] || (_cache[0] = ($event: any) => (($setup.newPassword) = $event)),
      onPrevPage: _cache[1] || (_cache[1] = ($event: any) => ($setup.prevPage($event))),
      onNextPage: _cache[2] || (_cache[2] = ($event: any) => ($setup.nextPage($event))),
      onComplete: $setup.complete
    }, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_KeepAlive, null, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ], 1024 /* DYNAMIC_SLOTS */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["newPassword"])
  ], 64 /* STABLE_FRAGMENT */))
}