import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-532ada27"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "badge active"
}
const _hoisted_2 = {
  key: 1,
  class: "badge deactivated"
}
const _hoisted_3 = {
  key: 2,
  class: "badge jailed"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.validator.status === $setup.ValidatorStatus.Bonded)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('STAKING_VIEW.VALIDATOR_STATUS.ACTIVE')), 1 /* TEXT */))
    : (!$props.validator.jailed)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('STAKING_VIEW.VALIDATOR_STATUS.INACTIVE')), 1 /* TEXT */))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('STAKING_VIEW.VALIDATOR_STATUS.JAILED')), 1 /* TEXT */))
}