import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d2790b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tile" }
const _hoisted_2 = { class: "text" }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = { class: "value small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Icon, { name: "Clock" }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('DASHBOARD_VIEW.AVERAGE_BLOCK_TIME')), 1 /* TEXT */),
      _createElementVNode("span", _hoisted_4, [
        _createVNode($setup["FormattedNumber"], {
          amount: $setup.blockStore.getAverageBlockTime,
          precision: 2
        }, null, 8 /* PROPS */, ["amount"]),
        _createTextVNode("s")
      ]),
      _createCommentVNode(" <span class=\"value small\">{{ blockStore.getAverageBlockTimeView() }}s</span> ")
    ])
  ]))
}