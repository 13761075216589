import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e7394234"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "info" }
const _hoisted_3 = { style: {"color":"gray"} }
const _hoisted_4 = { style: {"color":"gray"} }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.proposalPlan)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.HEIGHT")), 1 /* TEXT */),
          _createElementVNode("span", null, _toDisplayString($props.proposalPlan.height), 1 /* TEXT */),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.INFO")), 1 /* TEXT */),
          _createElementVNode("span", null, _toDisplayString($props.proposalPlan.info), 1 /* TEXT */),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.NAME")), 1 /* TEXT */),
          _createElementVNode("span", null, _toDisplayString($props.proposalPlan.name), 1 /* TEXT */),
          (!$setup.alreadyDone)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.ESTIMATED_TIME_LEFT")), 1 /* TEXT */),
                _createElementVNode("div", null, [
                  _createVNode($setup["EstimatedTimeLeft"], {
                    height: Number($setup.props.proposalPlan.height)
                  }, null, 8 /* PROPS */, ["height"])
                ]),
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.ESTIMATED_DATE")), 1 /* TEXT */),
                _createElementVNode("div", null, [
                  _createVNode($setup["EstimatedUpgradeDate"], {
                    height: Number($setup.props.proposalPlan.height)
                  }, null, 8 /* PROPS */, ["height"])
                ])
              ], 64 /* STABLE_FRAGMENT */))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.UPDATED_IN_BLOCK")), 1 /* TEXT */),
                _createElementVNode("span", {
                  innerHTML: $setup.getLink($setup.useConfigurationStore().config.explorerUrl + '/blocks/' + $setup.props.proposalPlan.height)
                }, null, 8 /* PROPS */, _hoisted_5)
              ], 64 /* STABLE_FRAGMENT */))
        ])
      ]))
    : _createCommentVNode("v-if", true)
}