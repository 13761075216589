import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29d1c1f1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin-top":"40px","padding-bottom":"60px"} }
const _hoisted_2 = { class: "login_container box-shadow" }
const _hoisted_3 = { class: "login_container__header" }
const _hoisted_4 = { style: {"font-weight":"900","padding-top":"20px"} }
const _hoisted_5 = { class: "login_container__body" }
const _hoisted_6 = { class: "flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("SECTION_TITLES.PROVIDEVERIFICATIONCODE")), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", null, "A verification code has been sent to " + _toDisplayString($setup.email) + ". Please check your mailbox and enter code below.", 1 /* TEXT */),
        _createVNode($setup["OtpComponent"], {
          "digit-count": "12",
          "onUpdate:otp": _cache[0] || (_cache[0] = ($event: any) => ($setup.activationCode = $event))
        }),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Button, {
            class: "p-button p-component secondary",
            style: {"width":"40%"},
            onClick: _cache[1] || (_cache[1] = ($event: any) => {$setup.emit('prevPage', {pageIndex: 1});})
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('COMMON.BACK')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_Button, {
            class: "p-button p-component secondary",
            disabled: !$setup.activationCode,
            style: {"width":"40%"},
            onClick: $setup.next
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('COMMON.NEXT')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled"])
        ])
      ])
    ])
  ]))
}