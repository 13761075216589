import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a53f65c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "info" }
const _hoisted_2 = { class: "info-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Icon, { name: "Info" }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t($props.header, $props.headerVariables)), 1 /* TEXT */),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.texts, (itext) => {
        return (_openBlock(), _createElementBlock("span", {
          key: itext,
          class: "info-text"
        }, _toDisplayString(_ctx.$t(itext)), 1 /* TEXT */))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}