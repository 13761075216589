export enum ToastsTypeEnum {
  STAKING_POOL,
  TOTAL_SUPPLY,
  CIRCULATING_SUPPLY,
  COMMUNITY_POOL,
  STRATEGIC_REVERSE_POOL,
  AIRDROP_POOL,
  DISTRIBUTOR_PARAMS,
  VALIDATORS,
  PROPOSALS,
  PROPOSAL,
  PROPOSAL_TALLY_RESULT,
  PROPOSAL_USER_VOTE,
  TALLY_PARAMS,
  DEPOSIT_PARAMS,
  PROPOSAL_DETAILS_TALLY,
  PROPOSAL_DETAILS_TALLY_LIST,
  LATEST_BLOCK,
  AVERAGE_BLOCK_TIME,
  PUBLIC_SALE_SERVICE,
  USER_SERVICE,
  TOKEN_PRICE

}
