import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("span", null, [
    _createTextVNode(_toDisplayString($setup.trueDate), 1 /* TEXT */)
  ])), [
    [_directive_tooltip, { value: $setup.retrieveConvertedTime(), disabled:!$setup.props.showTooltip, class:'coin-amount-tooltip'}]
  ])
}