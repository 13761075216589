<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line class="color" x1="2" y1="2" x2="22" y2="22"></line><path class="color" d="M8.5 16.5a5 5 0 0 1 7 0"></path><path class="color" d="M2 8.82a15 15 0 0 1 4.17-2.65"></path><path class="color" d="M10.66 5c4.01-.36 8.14.9 11.34 3.76"></path><path class="color" d="M16.85 11.25a10 10 0 0 1 2.22 1.68"></path><path class="color" d="M5 13a10 10 0 0 1 5.24-2.76"></path><line class="color" x1="12" y1="20" x2="12.01" y2="20"></line></svg>
</template>

<script>

</script>

<style scoped>
.color {
  color: #8c8c8c;
}
</style>
