import { createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f2604e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "userdata" }
const _hoisted_2 = { class: "userdata__accountData" }
const _hoisted_3 = { class: "userdata__accountData-base" }
const _hoisted_4 = { class: "userdata__rewards" }
const _hoisted_5 = { class: "userdata__rewardAmount" }
const _hoisted_6 = { class: "userdata__claimText" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["AmountView"], {
          coins: $setup.representData,
          showVesting: true,
          reduceBigNumber: true,
          precision: 2
        }, {
          "logo-front": _withCtx(() => [
            _createVNode($setup["C4EIcon"], {
              icon: "c4e-circle",
              size: 30
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["coins"]),
        _createCommentVNode("      <div class=\"vesting\">"),
        _createCommentVNode("        <div class=\"userdata__accountData-vesting-first\" v-if=\"useUserStore().isContinuousVestingAccount\">"),
        _createCommentVNode("          <div>"),
        _createCommentVNode("            <p>{{ $t('USER_DATA.LOCKED') }}</p>"),
        _createCommentVNode("            <CoinAmount :amount=\"locked\" :show-denom=\"true\"/>"),
        _createCommentVNode("          </div>"),
        _createCommentVNode("        </div>"),
        _createCommentVNode("        <div class=\"userdata__accountData-vesting\" v-if=\"useUserStore().isContinuousVestingAccount\">"),
        _createCommentVNode("          <div>"),
        _createCommentVNode("            <p>{{ $t('USER_DATA.VESTING_END') }}</p>"),
        _createCommentVNode("            <p><DateCommon :date=\"useUserStore().getAccount.continuousVestingData?.endTime\"/></p>"),
        _createCommentVNode("          </div>"),
        _createCommentVNode("        </div>"),
        _createCommentVNode("        <div class=\"userdata__amounts_last\" v-if=\"!useUserStore().isContinuousVestingAccount\"></div>"),
        _createCommentVNode("      </div>")
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createCommentVNode("      <AmountView"),
        _createCommentVNode("        :coins=\" [{amount: useUserStore().getSpendableBalance || 0, header: i18n.global.t('USER_DATA.CLAIM_HEADER'), showDenom:true, showPrice:true}]\""),
        _createCommentVNode("        :show-denom=\"true\""),
        _createCommentVNode("        :precision=\"4\""),
        _createCommentVNode("        :orig-denom=\"useConfigurationStore().config.getConvertedDenom()\""),
        _createCommentVNode("        :reduce-big-number=\"false\">"),
        _createCommentVNode("      </AmountView>"),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('USER_DATA.CLAIM_HEADER')), 1 /* TEXT */),
        _createVNode($setup["CoinAmount"], {
          class: "userdata__claimAmount",
          amount: $setup.useUserStore().getTotalRewards,
          "show-denom": true
        }, null, 8 /* PROPS */, ["amount"]),
        _createCommentVNode("      <CoinAmount style=\"font-size: 0.7rem\""),
        _createCommentVNode("                  :amount=\"calculatePrice(useUserStore().getTotalRewards, price)\""),
        _createCommentVNode("                  default-view-denom=\"$\""),
        _createCommentVNode("                  :tooltip-precision=2"),
        _createCommentVNode("                  :precision=\"2\""),
        _createCommentVNode("                  :reduce-big-number=\"true\" :show-tooltip=\"true\" :show-denom=\"true\"  :denom-as-prefix=\"true\"/>")
      ]),
      _createVNode(_component_Button, {
        class: "outlined-secondary",
        onClick: $setup.claimRewards
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('USER_DATA.CLAIM_REWARDS')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ])
  ]))
}