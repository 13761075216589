import { createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5445008c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loginPopup" }
const _hoisted_2 = { class: "loginPopup__holder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "loginPopup__background",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_Transition, _mergeProps($setup.loginType, {
        name: "slide-fade",
        mode: "out-in"
      }), {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent($setup.loginType), {
            onKeplr: $setup.keplrConnect,
            onLeap: $setup.leapConnect,
            onBack: _cache[1] || (_cache[1] = ($event: any) => ($setup.loginType = $setup.LoginChoose)),
            onTypeChange: _cache[2] || (_cache[2] = (comp) => $setup.loginType = comp),
            onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close'))),
            onCosmostation: $setup.cosmostationConnect,
            showAddressOption: $setup.props.showAddressOption
          }, null, 40 /* PROPS, NEED_HYDRATION */, ["showAddressOption"]))
        ]),
        _: 1 /* STABLE */
      }, 16 /* FULL_PROPS */)
    ])
  ]))
}