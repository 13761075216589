import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64d51801"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tile" }
const _hoisted_2 = {
  style: {"display":"flex","flex-direction":"row","text-align":"left"},
  class: "text left-element"
}
const _hoisted_3 = { class: "label" }
const _hoisted_4 = { class: "text right-element" }
const _hoisted_5 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Icon, { name: "TrendingUp" }),
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('DASHBOARD_VIEW.INFLATION')), 1 /* TEXT */),
        _createVNode($setup["PercentsView"], {
          amount: $setup.useTokensStore().inflation,
          precision: 2,
          "remove-dec": false
        }, null, 8 /* PROPS */, ["amount"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('DASHBOARD_VIEW.TARGET_INFLATION')), 1 /* TEXT */),
      _createVNode($setup["PercentsView"], {
        amount: $setup.useTokensStore().inflation * $setup.useConfigurationStore().config.targetInflationAprMultiplier,
        precision: 2
      }, null, 8 /* PROPS */, ["amount"])
    ])
  ]))
}