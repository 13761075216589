import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ea6c295"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "code_container box-shadow" }
const _hoisted_2 = { class: "code_container__info" }
const _hoisted_3 = { class: "code_container__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, " A verification code has been sent to " + _toDisplayString($setup.useUserServiceStore().userEmail) + ". Please check your mailbox and enter code below. ", 1 /* TEXT */),
    _createVNode($setup["OtpComponent"], {
      "digit-count": "12",
      "onUpdate:otp": _cache[0] || (_cache[0] = ($event: any) => ($setup.activationCode = $event))
    }),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Button, {
        class: "p-button p-component secondary",
        onClick: $setup.onActivateClick
      }, {
        default: _withCtx(() => [
          _createTextVNode("Activate")
        ]),
        _: 1 /* STABLE */
      })
    ])
  ]))
}