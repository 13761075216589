import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2736e2d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "userProfile" }
const _hoisted_2 = { class: "userProfile__holder" }
const _hoisted_3 = { class: "userProfile__extra" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("  <AddressModal style=\"position: fixed; z-index: 99999\" @close=\"hideAddAddress\" @submit=\"submitEmail\" v-if=\"showAddressAdd\" />"),
    ($setup.showApprovalModal)
      ? (_openBlock(), _createBlock($setup["ApprovalModal"], {
          key: 0,
          onClose: $setup.hideApprovalModal,
          onSubmit: $setup.hideApprovalModal
        }))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["TabView"], null, {
          default: _withCtx(() => [
            _createVNode($setup["TabPanel"], { class: "userProfile__tabHeader" }, {
              header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('PROFILE_VIEW.ACCOUNT_INFO')), 1 /* TEXT */)
              ]),
              default: _withCtx(() => [
                _createVNode($setup["AccountInfo"], {
                  accordion: false,
                  onOpenModal: $setup.showAddressAddModal,
                  onOpenApproval: $setup.showApprovalModalFunc
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode($setup["TabPanel"], null, {
              header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('PROFILE_VIEW.TRANSACTIONS')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        ($setup.useUserServiceStore().isLoggedIn)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              class: "p-button p-component secondary userProfile__btn",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.logOutAccount()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('BUTTONS.LOGOUT')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _createCommentVNode("    <PayModal v-model:display=\"showModal\" v-model:reservation=\"selectedReservation\" @close=\"showModal = false\" />")
    ])
  ], 64 /* STABLE_FRAGMENT */))
}