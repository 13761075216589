import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["Dialog"], {
      visible: $props.visible,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('closeModal'))),
      modal: "",
      header: "Payment",
      baseZIndex: -100,
      style: { width: '80vw' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode($setup["InvestmentCalculator"], {
            onOnBuy: $setup.onBuyClick,
            "disable-stablecoin": $setup.sourceAddress == undefined,
            "first-input-blocked": true,
            "is-declaration": false,
            "second-input-default-value": Number($props.reservation.leftToPayInStableCoin())
          }, null, 8 /* PROPS */, ["disable-stablecoin", "second-input-default-value"])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["visible"])
  ]))
}