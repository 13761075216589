import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../assets/stablecoin.png'


const _withScopeId = n => (_pushScopeId("data-v-d6c2cd58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "calculatorC4E" }
const _hoisted_2 = { class: "calculatorC4E__header" }
const _hoisted_3 = { style: {"float":"right","font-weight":"300","font-size":"19px"} }
const _hoisted_4 = { class: "calculatorC4E__body" }
const _hoisted_5 = { style: {"flex":"1 1"} }
const _hoisted_6 = { style: {"display":"flex","flex-wrap":"wrap","box-sizing":"border-box"} }
const _hoisted_7 = { class: "calculatorC4E__inputContainer" }
const _hoisted_8 = { class: "text" }
const _hoisted_9 = { style: {"display":"flex","min-width":"350px"} }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = {
  key: 0,
  class: "flex align-items-center"
}
const _hoisted_12 = { class: "flag flex align-items-center" }
const _hoisted_13 = { class: "flex align-items-center" }
const _hoisted_14 = { class: "flag flex align-items-center" }
const _hoisted_15 = { class: "calculatorC4E__inputContainer" }
const _hoisted_16 = { class: "text" }
const _hoisted_17 = { style: {"display":"flex","min-width":"350px"} }
const _hoisted_18 = ["disabled"]
const _hoisted_19 = {
  key: 0,
  class: "flex align-items-center"
}
const _hoisted_20 = { class: "flag" }
const _hoisted_21 = {
  key: 1,
  src: _imports_0,
  alt: "stablecoin symbol",
  class: "h-full"
}
const _hoisted_22 = { class: "flex align-items-center" }
const _hoisted_23 = { class: "flag" }
const _hoisted_24 = {
  key: 1,
  src: _imports_0,
  alt: "stablecoin symbol",
  class: "h-full"
}
const _hoisted_25 = { style: {"margin-top":"30px"} }
const _hoisted_26 = { class: "calculatorC4E__btnSection" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.$t('BUY_TOKENS_VIEW.CALCULATE_INVESTMENT')) + " ", 1 /* TEXT */),
      _createElementVNode("span", _hoisted_3, [
        _createVNode($setup["TooltipComponent"], {
          tooltipText: _ctx.$t('BUY_TOKENS_VIEW.REQUIREMENTS_TIP')
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('BUY_TOKENS_VIEW.REQUIREMENTS')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["tooltipText"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('BUY_TOKENS_VIEW.I_WANT_TO_BUY')), 1 /* TEXT */),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_9, [
                _withDirectives(_createElementVNode("input", {
                  onPaste: $setup.onFirstInputChange,
                  onKeyup: $setup.onFirstInputChange,
                  style: {"width":"100%"},
                  class: "calculatorC4E__input",
                  type: "number",
                  disabled: $props.firstInputBlocked,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.firstValueInput) = $event))
                }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_10), [
                  [_vModelText, $setup.firstValueInput]
                ]),
                _createVNode($setup["Dropdown"], {
                  modelValue: $setup.firstValue.currency,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.firstValue.currency) = $event)),
                  options: [$setup.Currency.C4E],
                  disabled: true,
                  placeholder: "Select network",
                  style: {"max-width":"200px","height":"52px"},
                  class: "dropdown flex align-items-center"
                }, {
                  value: _withCtx((slotProps) => [
                    (slotProps.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _createElementVNode("div", _hoisted_12, [
                            _createVNode($setup["C4EIcon"], {
                              size: "30",
                              icon: "c4e-circle"
                            })
                          ]),
                          _createElementVNode("div", null, _toDisplayString(slotProps.value), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true)
                  ]),
                  option: _withCtx((slotProps) => [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode($setup["C4EIcon"], {
                          size: "30",
                          icon: "c4e-circle"
                        })
                      ]),
                      _createElementVNode("div", null, _toDisplayString(slotProps.option), 1 /* TEXT */)
                    ])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('BUY_TOKENS_VIEW.I_WANT_TO_INVEST')), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_17, [
                _withDirectives(_createElementVNode("input", {
                  onPaste: $setup.onSecondInputChange,
                  onKeyup: $setup.onSecondInputChange,
                  style: {"width":"100%"},
                  class: "calculatorC4E__input",
                  type: "number",
                  disabled: $props.firstInputBlocked,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.secondValueInput) = $event))
                }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_18), [
                  [_vModelText, $setup.secondValueInput]
                ]),
                _createVNode($setup["Dropdown"], {
                  modelValue: $setup.secondValue.currency,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.secondValue.currency) = $event)),
                  options: $setup.currencyList,
                  placeholder: "Select network",
                  style: {"max-width":"200px","height":"52px"},
                  class: "dropdown flex align-items-center"
                }, {
                  value: _withCtx((slotProps) => [
                    (slotProps.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                          _createElementVNode("div", _hoisted_20, [
                            (slotProps.value !== $setup.Currency.STABLE)
                              ? (_openBlock(), _createBlock($setup["CountryFlag"], {
                                  key: 0,
                                  country: $setup.flagSelector[slotProps.value]
                                }, null, 8 /* PROPS */, ["country"]))
                              : (_openBlock(), _createElementBlock("img", _hoisted_21))
                          ]),
                          _createElementVNode("div", null, _toDisplayString(slotProps.value), 1 /* TEXT */)
                        ]))
                      : _createCommentVNode("v-if", true)
                  ]),
                  option: _withCtx((slotProps) => [
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, [
                        (slotProps.option !== $setup.Currency.STABLE)
                          ? (_openBlock(), _createBlock($setup["CountryFlag"], {
                              key: 0,
                              country: $setup.flagSelector[slotProps.option]
                            }, null, 8 /* PROPS */, ["country"]))
                          : (_openBlock(), _createElementBlock("img", _hoisted_24))
                      ]),
                      _createElementVNode("div", null, _toDisplayString(slotProps.option), 1 /* TEXT */)
                    ])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_25, " 1 " + _toDisplayString($setup.firstValue.currency) + " = " + _toDisplayString($setup.exchangeRate?.toString()) + " " + _toDisplayString($setup.secondValue.currency), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_26, [
          _createCommentVNode("          <span style=\" font-weight: 300;font-size: 19px;\">{{$t('BUY_TOKENS_VIEW.REQUIREMENTS')}} <TooltipComponent :tooltipText=\"$t('BUY_TOKENS_VIEW.REQUIREMENTS_TIP')\" /></span>"),
          _withDirectives((_openBlock(), _createElementBlock("div", null, [
            _createVNode(_component_Button, {
              class: "p-button p-component secondary",
              disabled: !$setup.minimumRequired(),
              style: {"width":"141px"},
              onClick: $setup.onBuy
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('BUTTONS.BUY')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["disabled"])
          ])), [
            [_directive_tooltip, { value: 'You cannot invest less then $25 and more than $10000', disabled: $setup.minimumRequired()}]
          ])
        ])
      ])
    ])
  ]))
}