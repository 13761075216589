import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("  BlockUI nie zawsze działa, czasami pozostaje się nie wyłącza i ekran zostaje zablokowany"),
    _createCommentVNode("  <BlockUI id=\"blokUi\" :blocked=\"splashStore.splashOn\" :fullScreen=\"true\">"),
    _createCommentVNode("    <div v-if=\"splashStore.splashOn\" class=\"lds-hourglass\"/>"),
    _createElementVNode("div", null, [
      _createVNode($setup["Loading"], {
        active: $setup.splashStore.splashOn,
        "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => (($setup.splashStore.splashOn) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode($setup["C4ESpinner"], {
            class: "c4e-icon",
            size: 120
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["active"]),
      _createCommentVNode("    <loading v-model:active=\"ttt\"/>")
    ])
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}