import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "expansion-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: $setup.getValues(),
    class: "row-spacing",
    selectionMode: "single",
    showGridlines: false,
    lazy: $setup.isLazyLoading(),
    "removable-sort": true,
    totalRecords: $setup.getAmount(),
    paginator: $props.paginator,
    rows: 10,
    first: $setup.firstRecord,
    "onUpdate:first": _cache[1] || (_cache[1] = ($event: any) => (($setup.firstRecord) = $event)),
    template: "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink",
    onPage: _cache[2] || (_cache[2] = ($event: any) => ($setup.pageEvent($event))),
    onSort: _cache[3] || (_cache[3] = ($event: any) => ($setup.sortEvent($event))),
    sortMode: "single",
    globalFilterFields: $setup.getGlobalFilterFields(),
    expandedRows: $setup.getExpandedRows(),
    rowsPerPageOptions: [10,20,50],
    filters: $setup.getFilters()
  }, _createSlots({
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "columns")
    ]),
    _: 2 /* DYNAMIC */
  }, [
    ($setup.slotPassed('header'))
      ? {
          name: "header",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "header")
          ]),
          key: "0"
        }
      : undefined,
    ($setup.slotPassed('groupheader'))
      ? {
          name: "groupheader",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "groupheader")
          ]),
          key: "1"
        }
      : undefined,
    ($setup.slotPassed('empty'))
      ? {
          name: "empty",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "empty")
          ]),
          key: "2"
        }
      : undefined,
    ($setup.slotPassed('loading'))
      ? {
          name: "loading",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "loading")
          ]),
          key: "3"
        }
      : undefined,
    ($setup.slotPassed('expanded-columns'))
      ? {
          name: "expansion",
          fn: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_1, [
              _renderSlot(_ctx.$slots, "expanded-columns", { expandedData: slotProps })
            ])
          ]),
          key: "4"
        }
      : undefined,
    ($setup.slotPassed('paginatorstart') || $setup.slotPassed('paginatorstartCustom'))
      ? {
          name: "paginatorstart",
          fn: _withCtx(() => [
            ($setup.slotPassed('paginatorstartCustom'))
              ? _renderSlot(_ctx.$slots, "paginatorstartCustom", { key: 0 })
              : _createCommentVNode("v-if", true),
            ($setup.slotPassed('paginatorstart'))
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 1,
                  type: "button",
                  icon: "pi pi-refresh",
                  class: "p-button-text",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.reload()))
                }))
              : _createCommentVNode("v-if", true)
          ]),
          key: "5"
        }
      : undefined,
    ($setup.slotPassed('paginatorend'))
      ? {
          name: "paginatorend",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "paginatorend")
          ]),
          key: "6"
        }
      : undefined,
    ($setup.slotPassed('footer'))
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "footer")
          ]),
          key: "7"
        }
      : undefined,
    ($setup.slotPassed('groupfooter'))
      ? {
          name: "groupfooter",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "groupfooter")
          ]),
          key: "8"
        }
      : undefined
  ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "lazy", "totalRecords", "paginator", "first", "globalFilterFields", "expandedRows", "filters"]))
}