import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-82cc3526"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "description"
}
const _hoisted_2 = { style: {"margin-top":"20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.proposal)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("GOVERNANCE_VIEW.DESCRIPTION")), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_2, [
          _createVNode($setup["MarkdownRender"], {
            source: $setup.getDescription()
          }, null, 8 /* PROPS */, ["source"])
        ])
      ]))
    : _createCommentVNode("v-if", true)
}