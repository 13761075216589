import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51db5f36"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.validator.description.pictureUrl && !$setup.imageLoadingError)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: "c4e-validator-image",
        src: $props.validator.description.pictureUrl,
        onError: $setup.setAltImg
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1))
    : _createCommentVNode("v-if", true)
}