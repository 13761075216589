<template>
  <div class="container">
    <h4>{{ $t('TERMS_CONDITIONS_VIEW.TITLES.terms_conditions') }}</h4>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.terms_conditions_1') }}</div>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.terms_conditions_2') }}</div>

    <h4>{{ $t('TERMS_CONDITIONS_VIEW.TITLES.rights_obligations') }}</h4>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.rights_obligations') }}</div>

    <h4>{{ $t('TERMS_CONDITIONS_VIEW.TITLES.product') }}</h4>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.product') }}</div>

    <h4>{{ $t('TERMS_CONDITIONS_VIEW.TITLES.obligations_warranties') }}</h4>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.obligations_warranties_1') }}</div>
    <ul>
      <li>
        <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.obligations_warranties_2') }}</div>
      </li>
      <li>
        <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.obligations_warranties_3') }}</div>
      </li>
      <li>
        <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.obligations_warranties_4') }}</div>
      </li>
      <li>
        <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.obligations_warranties_5') }}</div>
      </li>
    </ul>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.obligations_warranties_6') }}</div>

    <h4>{{ $t('TERMS_CONDITIONS_VIEW.TITLES.compliance_audit') }}</h4>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.compliance_audit') }}</div>

    <h4>{{ $t('TERMS_CONDITIONS_VIEW.TITLES.service_limitations') }}</h4>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.service_limitations') }}</div>

    <h4>{{ $t('TERMS_CONDITIONS_VIEW.TITLES.intellectual_property_rights') }}</h4>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.intellectual_property_rights') }}</div>

    <h4>{{ $t('TERMS_CONDITIONS_VIEW.TITLES.termination') }}</h4>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.termination') }}</div>

    <h4>{{ $t('TERMS_CONDITIONS_VIEW.TITLES.limitation_of_liability') }}</h4>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.limitation_of_liability_1') }}</div>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.limitation_of_liability_2') }}</div>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.limitation_of_liability_3') }}</div>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.limitation_of_liability_4') }}</div>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.limitation_of_liability_5') }}</div>

    <h4>{{ $t('TERMS_CONDITIONS_VIEW.TITLES.restrictions') }}</h4>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.restrictions_1') }}</div>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.restrictions_2') }}</div>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.restrictions_3') }}</div>

    <h4>{{ $t('TERMS_CONDITIONS_VIEW.TITLES.indemnification') }}</h4>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.indemnification_1') }}</div>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.indemnification_2') }}</div>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.indemnification_3') }}</div>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.indemnification_4') }}</div>

    <h4>{{ $t('TERMS_CONDITIONS_VIEW.TITLES.no_class_proceedings') }}</h4>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.no_class_proceedings') }}</div>

    <h4>{{ $t('TERMS_CONDITIONS_VIEW.TITLES.third_party_rights') }}</h4>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.third_party_rights') }}</div>

    <h4>{{ $t('TERMS_CONDITIONS_VIEW.TITLES.entire_agreement') }}</h4>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.entire_agreement') }}</div>

    <h4>{{ $t('TERMS_CONDITIONS_VIEW.TITLES.severability') }}</h4>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.severability') }}</div>

    <h4>{{ $t('TERMS_CONDITIONS_VIEW.TITLES.assignment') }}</h4>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.assignment') }}</div>

    <h4>{{ $t('TERMS_CONDITIONS_VIEW.TITLES.no_partnership') }}</h4>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.no_partnership') }}</div>

    <h4>{{ $t('TERMS_CONDITIONS_VIEW.TITLES.force_majeure') }}</h4>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.force_majeure') }}</div>

    <h4>{{ $t('TERMS_CONDITIONS_VIEW.TITLES.law_jurisdiction') }}</h4>
    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.law_jurisdiction') }}</div>

    <h4>{{ $t('TERMS_CONDITIONS_VIEW.TITLES.contact') }}</h4>
<!--    <div class="text">{{ $t('TERMS_CONDITIONS_VIEW.DESCRIPTIONS.CONTACTS') }}</div>-->


  </div>

</template>

<script setup lang="ts">

</script>
<style lang="scss" scoped>
h4 {
  margin-top: 1em;
  font-weight: bold;
}
.container {
  text-align: start;
}
.text {
  margin-bottom: 1em;
}

</style>
<style>

</style>
