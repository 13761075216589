import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cae92b8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "claimingOptionsPopup" }
const _hoisted_2 = {
  key: 0,
  class: "claimingOptionsPopup__holder"
}
const _hoisted_3 = { class: "claimingOptionsPopup__content" }
const _hoisted_4 = {
  key: 1,
  class: "claimingOptionsPopup__holder"
}
const _hoisted_5 = { class: "claimingOptionsPopup__content" }
const _hoisted_6 = { class: "loginEmail__description" }
const _hoisted_7 = { class: "field" }
const _hoisted_8 = { class: "invalid-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "claimingOptionsPopup__background",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
    }),
    (!$setup.accountOfVestingType)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "claimingOptionsPopup__x",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
          }, [
            _createVNode($setup["Icon"], {
              name: "X",
              style: {"height":"25px"}
            })
          ]),
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('AIRDROP.CLAIM_TO_ADDRESS')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_3, _toDisplayString($setup.address), 1 /* TEXT */),
          _createVNode(_component_Button, { onClick: $setup.claim }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('COMMON.CLAIM')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.accountOfVestingType)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('AIRDROP.CLAIM_TO_DESTINATION_ADDRESS')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_5, [
            _createVNode($setup["Form"], {
              onSubmit: $setup.claim,
              "validation-schema": $setup.addressSchema,
              class: "loginEmail__body"
            }, {
              default: _withCtx(({ errors }) => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode($setup["Field"], {
                      modelValue: $setup.address,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.address) = $event)),
                      name: "address",
                      placeholder: " ",
                      type: "text",
                      class: _normalizeClass(["form-control", { 'is-invalid': errors.address }]),
                      style: {"width":"100%"}
                    }, null, 8 /* PROPS */, ["modelValue", "class"]),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('CONNECT.INSERT_ADDRESS')), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(errors.address ? errors.address : ""), 1 /* TEXT */),
                    _createVNode(_component_Button, { type: "submit" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('COMMON.CLAIM')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ])
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["validation-schema"])
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}