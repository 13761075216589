import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c9a3f29"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "m-0"
}
const _hoisted_2 = {
  key: 2,
  class: "m-0"
}
const _hoisted_3 = { class: "m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    ($setup.isLoggedIn && $setup.useUserStore().hasDelegations)
      ? (_openBlock(), _createElementBlock("h4", _hoisted_1, _toDisplayString(_ctx.$t("STAKING_VIEW.USER_DELEGATIONS")), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($setup.isLoggedIn && $setup.useUserStore().hasDelegations)
      ? (_openBlock(), _createBlock($setup["ValidatorsDataTable"], {
          key: 1,
          validators: $setup.useValidatorsStore().getUserDelgationsValidators,
          type: $setup.ValidatorsDataTableType.DELEGATIONS
        }, null, 8 /* PROPS */, ["validators", "type"]))
      : _createCommentVNode("v-if", true),
    ($setup.isLoggedIn && $setup.useUserStore().hasUndelegations)
      ? (_openBlock(), _createElementBlock("h4", _hoisted_2, _toDisplayString(_ctx.$t("STAKING_VIEW.USER_UNDELEGATIONS")), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($setup.isLoggedIn && $setup.useUserStore().hasUndelegations)
      ? (_openBlock(), _createBlock($setup["ValidatorsDataTable"], {
          key: 3,
          validators: $setup.useValidatorsStore().getUserUndelgationsValidators,
          type: $setup.ValidatorsDataTableType.UNDELEGATIONS
        }, null, 8 /* PROPS */, ["validators", "type"]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.$t("STAKING_VIEW.VALIDATORS")), 1 /* TEXT */),
    _createVNode($setup["TabView"], { lazy: "" }, {
      default: _withCtx(() => [
        _createVNode($setup["TabPanel"], null, {
          header: _withCtx(() => [
            _createVNode($setup["ValidatorsStatusLabel"], { status: "all" })
          ]),
          default: _withCtx(() => [
            _createVNode($setup["ValidatorsDataTable"], {
              validators: $setup.useValidatorsStore().getValidators,
              type: $setup.ValidatorsDataTableType.VALIDATORS
            }, null, 8 /* PROPS */, ["validators", "type"])
          ]),
          _: 1 /* STABLE */
        }),
        _createCommentVNode(" <TabPanel v-if=\"isLoggedIn\">\n        <template #header>\n          <ValidatorsStatusLabel status=\"staked\"/>\n        </template>\n        <ValidatorsDataTable :validators=\"useValidatorsStore().getUserValidators\" :type=\"ValidatorsDataTableType.VALIDATORS\"/>\n      </TabPanel> "),
        _createVNode($setup["TabPanel"], null, {
          header: _withCtx(() => [
            _createVNode($setup["ValidatorsStatusLabel"], { status: "active" })
          ]),
          default: _withCtx(() => [
            _createVNode($setup["ValidatorsDataTable"], {
              validators: $setup.useValidatorsStore().getActiveValidators,
              type: $setup.ValidatorsDataTableType.VALIDATORS
            }, null, 8 /* PROPS */, ["validators", "type"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["TabPanel"], null, {
          header: _withCtx(() => [
            _createVNode($setup["ValidatorsStatusLabel"], { status: "inactive" })
          ]),
          default: _withCtx(() => [
            _createVNode($setup["ValidatorsDataTable"], {
              validators: $setup.useValidatorsStore().getInactiveValidators,
              type: $setup.ValidatorsDataTableType.VALIDATORS
            }, null, 8 /* PROPS */, ["validators", "type"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}