import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tile" }
const _hoisted_2 = { class: "text" }
const _hoisted_3 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Icon, { name: "FileCheck" }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('DASHBOARD_VIEW.VALIDATORS')), 1 /* TEXT */),
      _createVNode($setup["FormattedNumber"], {
        class: "value small",
        amount: $setup.useValidatorsStore().getNumberOfActiveValidators,
        precision: 0
      }, null, 8 /* PROPS */, ["amount"])
    ])
  ]))
}