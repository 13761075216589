import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9f806c16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    modelValue: $setup.stakingAction,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.stakingAction) = $event)),
    onChange: $setup.onValueChange,
    options: $setup.actions,
    optionLabel: "name",
    placeholder: "Select a action",
    disabled: $props.disabled
  }, {
    value: _withCtx((slotProps) => [
      (slotProps.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode($setup["StakeManagementIcon"], {
              icon: slotProps.value.icon,
              class: "dropdown-option"
            }, null, 8 /* PROPS */, ["icon"]),
            _createTextVNode(" " + _toDisplayString(slotProps.value.name), 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    option: _withCtx((slotProps) => [
      _createElementVNode("div", null, [
        _createVNode($setup["StakeManagementIcon"], {
          icon: slotProps.option.icon,
          class: "dropdown-option"
        }, null, 8 /* PROPS */, ["icon"]),
        _createTextVNode(" " + _toDisplayString(slotProps.option.name), 1 /* TEXT */)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "disabled"]))
}