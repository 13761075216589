import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-281ae156"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "portfolioVesting__line" }
const _hoisted_2 = { class: "mobile-hidden" }
const _hoisted_3 = { class: "portfolioVesting__tile start-date" }
const _hoisted_4 = { class: "portfolioVesting__tile end-date" }
const _hoisted_5 = { class: "portfolioVesting__tile" }
const _hoisted_6 = { class: "portfolioVesting__tile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["C4EIcon"], {
        size: "75",
        icon: "c4e-grey-new"
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h3", null, _toDisplayString(new Date($props.vesting.startTime*1000).toLocaleString()), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h3", null, _toDisplayString(new Date($props.vesting.endTime*1000).toLocaleString()), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("h4", null, [
        _createVNode($setup["CoinAmount"], {
          amount: $setup.convertAmount($setup.sumVestingAmount()),
          precision: 2,
          "reduce-big-number": true,
          "show-tooltip": true,
          "show-denom": true
        }, null, 8 /* PROPS */, ["amount"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("h3", null, _toDisplayString($setup.calculateDays), 1 /* TEXT */)
    ])
  ]))
}