import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a174edc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "t-container" }
const _hoisted_2 = {
  key: 0,
  class: "t-header"
}
const _hoisted_3 = {
  key: 1,
  class: "t-header"
}
const _hoisted_4 = { key: 2 }
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.isSuccess())
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.i18n.global.t("TOAST.SUCCESS.TX_DELIVERY.TITLE")), 1 /* TEXT */))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($setup.i18n.global.t("TOAST.ERROR.TX_DELIVERY.TITLE")), 1 /* TEXT */)),
    (!$setup.isSuccess() && $props.errorTitleMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($props.errorTitleMessage), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", null, [
      ($props.tx?.transactionHash !== undefined)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "link",
            href: $setup.useConfigurationStore().config.explorerTx + $props.tx.transactionHash,
            target: "_blank"
          }, [
            _createTextVNode(_toDisplayString($setup.i18n.global.t('CONNECT.VIEW_EXPLORER')), 1 /* TEXT */),
            _createVNode($setup["Icon"], {
              class: "toast-icon",
              name: "ExternalLink"
            })
          ], 8 /* PROPS */, _hoisted_5))
        : _createCommentVNode("v-if", true)
    ]),
    _createCommentVNode("    <div class=\"t-body\">"),
    _createCommentVNode("      <div v-if=\"tx !== undefined && !isSuccess() && tx.rawLog\">{{tx.rawLog}}</div>"),
    _createCommentVNode("      <div v-if=\"errorMessage !== undefined && !isSuccess()\">{{errorMessage}}</div>"),
    _createCommentVNode("      <table v-if=\"tx !== undefined\" class=\"t-table\">"),
    _createCommentVNode("        <tr v-if=\"!isSuccess()\">"),
    _createCommentVNode("          <td>{{i18n.global.t(\"TOAST.ERROR.TX_DELIVERY.CODE\")}}</td>"),
    _createCommentVNode("          <td class=\"t-value\">{{tx.code}}</td>"),
    _createCommentVNode("        </tr>"),
    _createCommentVNode("        <tr>"),
    _createCommentVNode("          <td>{{i18n.global.t(\"TOAST.SUCCESS.TX_DELIVERY.HASH\")}}</td>"),
    _createCommentVNode("          <td class=\"t-value t-value-copy\" @click=\"copyTxHash\">"),
    _createCommentVNode("            {{ tx.transactionHash.slice(0, 6)}}...{{tx.transactionHash.slice(-6) }}<Icon class=\"toast-icon\" name=\"Copy\"/>"),
    _createCommentVNode("          </td>"),
    _createCommentVNode("        </tr>"),
    _createCommentVNode("        <tr>"),
    _createCommentVNode("          <td>{{i18n.global.t(\"TOAST.SUCCESS.TX_DELIVERY.GAS_USED\")}}</td>"),
    _createCommentVNode("          <td class=\"t-value\">{{ tx.gasUsed }}</td>"),
    _createCommentVNode("        </tr>"),
    _createCommentVNode("         <tr>"),
    _createCommentVNode("          <td>{{i18n.global.t(\"TOAST.SUCCESS.TX_DELIVERY.HEIGHT\")}}</td>"),
    _createCommentVNode("          <td class=\"t-value\">{{ tx.height }}</td>"),
    _createCommentVNode("        </tr>"),
    _createCommentVNode("      </table>"),
    _createCommentVNode("    </div>")
  ]))
}