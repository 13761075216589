import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ed4c03b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box-shadow buy-tokens-container" }
const _hoisted_2 = {
  key: 0,
  class: "back_container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.showBackButton())
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", { onClick: $setup.onBackClick }, [
            _createVNode(_component_Icon, { name: "ArrowLeft" }),
            _createTextVNode(" Back ")
          ])
        ]))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_KeepAlive, null, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ], 1024 /* DYNAMIC_SLOTS */))
      ]),
      _: 1 /* STABLE */
    })
  ]))
}