import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a1f0832"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "percentage",
  class: "percentageBar"
}
const _hoisted_2 = {
  class: "percentageBar__canvas",
  ref: "canva"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("canvas", _hoisted_2, null, 512 /* NEED_PATCH */)
  ], 512 /* NEED_PATCH */))
}