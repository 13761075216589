import { createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ac5107b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "goBack" }
const _hoisted_2 = { class: "container-grid" }
const _hoisted_3 = { class: "details" }
const _hoisted_4 = { class: "chart" }
const _hoisted_5 = { class: "description" }
const _hoisted_6 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "goBack__btn",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.router.push({name: 'proposalsList'})))
      }, [
        _createVNode($setup["Icon"], { name: "ArrowLeft" }),
        _createTextVNode("Back")
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      ($setup.everythingIsReady)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode($setup["ProposalDetails"], { proposal: $setup.proposal }, null, 8 /* PROPS */, ["proposal"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode($setup["DetailsChart"], {
                "proposal-detail-tally": $setup.proposalDetailsTally,
                proposal: $setup.proposal
              }, null, 8 /* PROPS */, ["proposal-detail-tally", "proposal"])
            ])
          ], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode($setup["ProposalDescription"], { proposal: $setup.proposal }, null, 8 /* PROPS */, ["proposal"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode($setup["ProposalAdditionalContent"], { proposal: $setup.proposal }, null, 8 /* PROPS */, ["proposal"])
    ])
  ]))
}