import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tile" }
const _hoisted_2 = { class: "text" }
const _hoisted_3 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Icon, { name: "Circle" }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('DASHBOARD_VIEW.CIRCULATING_SUPPLY')), 1 /* TEXT */),
      _createVNode($setup["CoinAmount"], {
        amount: $setup.supply,
        "show-denom": true,
        class: "value small"
      }, null, 8 /* PROPS */, ["amount"])
    ])
  ]))
}