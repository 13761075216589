import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.status === 'all')
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t(`STAKING_VIEW.TABS.ALL`)) + " (" + _toDisplayString($setup.useValidatorsStore().getNumberOfAllValidators) + ")", 1 /* TEXT */))
    : ($props.status === 'active')
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t(`STAKING_VIEW.TABS.ACTIVE`)) + " (" + _toDisplayString($setup.useValidatorsStore().getNumberOfActiveValidators) + ")", 1 /* TEXT */))
      : ($props.status === 'inactive')
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t(`STAKING_VIEW.TABS.INACTIVE`)) + " (" + _toDisplayString($setup.useValidatorsStore().getNumberOfInactiveValidators) + ")", 1 /* TEXT */))
        : ($props.status === 'staked')
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t(`STAKING_VIEW.TABS.STAKED`)), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
}